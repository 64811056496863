import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type Props = {
  password: string;
  required?: boolean;
  autoFocus?: boolean;
  width?: string | number;
  onChange: (password: any) => void;
  onKeyDown?: (key: any) => void;
};

const BasePassword: React.FC<Props> = ({
  password = "",
  required = true,
  autoFocus = true,
  width = 380,
  onChange,
  onKeyDown = (key: any) => key,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      sx={{ width }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {password !== "" ? (
              <IconButton onClick={() => setShowPassword((show) => !show)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ) : (
              <KeyIcon />
            )}
          </InputAdornment>
        ),
      }}
      margin="normal"
      required={required}
      autoFocus={autoFocus}
      id="password"
      name="password"
      label={t("BASE_FIELD_PASSWORD_LABEL")}
      type={showPassword ? "text" : "password"}
      value={password}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={(e) => {
        onKeyDown(e.key);
      }}
    />
  );
};

export default BasePassword;
