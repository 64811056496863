import { enLogsTab, elLogsTab } from "./Logs/translations";
import {
  enBackTestingPage,
  elBackTestingPage,
} from "./Backtesting/translations";

export const enAdminPage = {
  ...enLogsTab,
  ...enBackTestingPage,
};

export const elAdminPage = {
  ...elLogsTab,
  ...elBackTestingPage,
};
