import React, { useRef, useImperativeHandle, Ref } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { parseDates } from "../helpers";

const CandleLegend = React.forwardRef(
  (props: {}, ref: Ref<{ setLegend: any }>) => {
    const { t } = useTranslation();
    const openRef = useRef(null) as any;
    const closeRef = useRef(null) as any;
    const highRef = useRef(null) as any;
    const lowRef = useRef(null) as any;

    useImperativeHandle(ref, () => ({ setLegend }));

    const setLegend = (candleData: any, time: any = null) => {
      const candle = parseDates(candleData, time);
      const { open = null, close = null, high = null, low = null } = candle;
      openRef.current.innerText = open ? open : "";
      closeRef.current.innerText = close ? close : "";
      highRef.current.innerText = high ? high : "";
      lowRef.current.innerText = low ? low : "";
    };

    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          height: 20,
        }}
      >
        <Box>
          <Typography component="span">{t("CANDLE_OPEN")}: </Typography>
          <Typography component="span" ref={openRef}></Typography>
        </Box>
        <Box>
          <Typography component="span">{t("CANDLE_CLOSE")}: </Typography>
          <Typography component="span" ref={closeRef}></Typography>
        </Box>
        <Box>
          <Typography component="span">{t("CANDLE_HIGH")}: </Typography>
          <Typography component="span" ref={highRef}></Typography>
        </Box>
        <Box>
          <Typography component="span">{t("CANDLE_LOW")}: </Typography>
          <Typography component="span" ref={lowRef}></Typography>
        </Box>
      </Box>
    );
  }
);

export default CandleLegend;
