import React from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import Content from "../../App/Content/Content";
import LoginPage from "../../Pages/Login/Login";
import TfaPage from "../../Pages/Tfa/Tfa";
import TfaSetupPage from "../../Pages/TfaSetup/TfaSetup";
import HomePage from "../../Pages/Home/Home";
import NotFoundPage from "../../Pages/NotFound/NotFound";
import SignalsPage from "../../Pages/Signals/SignalsPage";
import AnalysisPage from "../../Pages/Analysis/AnalysisPage";
import ChartsPage from "../../Pages/Charts/ChartsPage";
import EconomyPage from "../../Pages/Economy/EconomyPage";
import OtherPage from "../../Pages/Other/OtherPage";
import SettingsPage from "../../Pages/Settings/SettingsPage";
import AdminPage from "../../Pages/Admin/AdminPage";
import { history } from "../../../utilities/history";

type Props = {
  headerChild: React.ReactNode;
  footerChild: React.ReactNode;
};

const CustomRouter: React.FC<Props> = ({ headerChild, footerChild }) => {
  return (
    <HistoryRouter
      history={history}
      future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
    >
      {headerChild}
      <Routes>
        <Route
          path={ROUTES.WILDCARD}
          element={<Content pageChild={<NotFoundPage />} />}
        />
        <Route
          path={ROUTES.LOGIN}
          element={<Content pageChild={<LoginPage />} />}
        />
        <Route
          path={ROUTES.TFA}
          element={<Content pageChild={<TfaPage />} />}
        />
        <Route
          path={ROUTES.TFA_SETUP}
          element={<Content pageChild={<TfaSetupPage />} />}
        />
        <Route
          path={ROUTES.HOME}
          element={<Content pageChild={<HomePage />} />}
        />
        <Route
          path={ROUTES.SIGNALS}
          element={<Content pageChild={<SignalsPage />} />}
        />
        <Route
          path={ROUTES.ANALYSIS}
          element={<Content pageChild={<AnalysisPage />} />}
        />
        <Route
          path={ROUTES.CHARTS}
          element={<Content pageChild={<ChartsPage />} />}
        />
        <Route
          path={ROUTES.OTHER}
          element={<Content pageChild={<OtherPage />} />}
        />
        <Route
          path={ROUTES.ECONOMY}
          element={<Content pageChild={<EconomyPage />} />}
        />
        <Route
          path={ROUTES.SETTINGS}
          element={<Content pageChild={<SettingsPage />} />}
        />
        <Route
          path={ROUTES.ADMIN}
          element={<Content pageChild={<AdminPage />} />}
        />
      </Routes>
      {footerChild}
    </HistoryRouter>
  );
};

export default CustomRouter;
