import { enSignalsGridCount, elSignalsGridCount } from "./Count/translations";
import { enSignalsGridFaq, elSignalsGridFaq } from "./Faq/translations";

export const enSignalsGrid = {
  SIGNALS_GRID_ID: "Id",
  SIGNALS_GRID_PAIR_ID: "Pair Id",
  SIGNALS_GRID_SYMBOL: "Symbol",
  SIGNALS_GRID_PR_NAME: "Provider",
  SIGNALS_GRID_ELAPSED: "Candles Back",
  SIGNALS_GRID_ESCAN_5: "Buy/Sell",
  SIGNALS_GRID_CROSS_50_200: "Golden/Death",
  SIGNALS_GRID_CROSS_GOLDEN_ELAPSED_50_200: "Last golden",
  SIGNALS_GRID_CROSS_DEATH_ELAPSED_50_200: "Last death",
  SIGNALS_GRID_MACD4CDIV_CLASSIC_20_50_9: "Classic",
  SIGNALS_GRID_MACD4CDIV_HIDDEN_20_50_9: "Hidden",
  SIGNALS_GRID_USCAN_5: "Buy/Sell",
  SIGNALS_GRID_VALUE_BUY: "Buy",
  SIGNALS_GRID_VALUE_SELL: "Sell",
  SIGNALS_GRID_VALUE_BULLISH: "Bullish",
  SIGNALS_GRID_VALUE_BEARISH: "Bearish",
  SIGNALS_GRID_VALUE_GOLDEN: "Golden",
  SIGNALS_GRID_VALUE_DEATH: "Death",
  SIGNALS_GRID_VALUE_POSITIVE: "Positive",
  SIGNALS_GRID_VALUE_NEGATIVE: "Negative",
  SIGNALS_GRID_SYMBOL_PREFIX: "Symbol prefix",
  SIGNALS_GRID_TYPE_NAME: "Asset type",
  SIGNALS_GRID_LAUNCH_ICON: "Actions",
  SIGNALS_ESCAN_IS_BUY_5: "Buy",
  SIGNALS_ESCAN_IS_SELL_5: "Sell",
  SIGNALS_ESCAN_ELAPSED_5: "Candles Back Given",
  SIGNALS_CROSS_IS_GOLDEN_50_200: "Golden",
  SIGNALS_CROSS_IS_DEATH_50_200: "Death",
  SIGNALS_MACD4CDIV_C_BULL_20_50_9: "Classic Bullish",
  SIGNALS_MACD4CDIV_C_BEAR_20_50_9: "Classic Bearish",
  SIGNALS_MACD4CDIV_H_BULL_20_50_9: "Hidden Bullish",
  SIGNALS_MACD4CDIV_H_BEAR_20_50_9: "Hidden Bearish",
  SIGNALS_USCAN_IS_BUY_5: "Buy",
  SIGNALS_USCAN_IS_SELL_5: "Sell",
  SIGNALS_USCAN_ELAPSED_5: "Candles Back Given",
  ...enSignalsGridCount,
  ...enSignalsGridFaq,
};

export const elSignalsGrid = {
  SIGNALS_GRID_ID: "Αναγνωριστικό",
  SIGNALS_GRID_PAIR_ID: "Αναγνωριστικό Ζεύγους",
  SIGNALS_GRID_SYMBOL: "Σύμβολο",
  SIGNALS_GRID_PR_NAME: "Πάροχος",
  SIGNALS_GRID_ELAPSED: "Κεριά Πίσω",
  SIGNALS_GRID_ESCAN_5: "Buy/Sell",
  SIGNALS_GRID_CROSS_50_200: "Golden/Death",
  SIGNALS_GRID_CROSS_GOLDEN_ELAPSED_50_200: "Τελευταίο golden",
  SIGNALS_GRID_CROSS_DEATH_ELAPSED_50_200: "Τελευταίο death",
  SIGNALS_GRID_MACD4CDIV_CLASSIC_20_50_9: "Classic",
  SIGNALS_GRID_MACD4CDIV_HIDDEN_20_50_9: "Hidden",
  SIGNALS_GRID_USCAN_5: "Buy/Sell",
  SIGNALS_GRID_VALUE_BUY: "Buy",
  SIGNALS_GRID_VALUE_SELL: "Sell",
  SIGNALS_GRID_VALUE_BULLISH: "Bullish",
  SIGNALS_GRID_VALUE_BEARISH: "Bearish",
  SIGNALS_GRID_VALUE_GOLDEN: "Golden",
  SIGNALS_GRID_VALUE_DEATH: "Death",
  SIGNALS_GRID_VALUE_POSITIVE: "Positive",
  SIGNALS_GRID_VALUE_NEGATIVE: "Negative",
  SIGNALS_GRID_SYMBOL_PREFIX: "Πρόθεμα συμβόλου",
  SIGNALS_GRID_TYPE_NAME: "Τύπος στοιχείου",
  SIGNALS_GRID_LAUNCH_ICON: "Ενέργειες",
  SIGNALS_ESCAN_IS_BUY_5: "Buy",
  SIGNALS_ESCAN_IS_SELL_5: "Sell",
  SIGNALS_ESCAN_ELAPSED_5: "Κεριά Πίσω",
  SIGNALS_CROSS_IS_GOLDEN_50_200: "Golden",
  SIGNALS_CROSS_IS_DEATH_50_200: "Death",
  SIGNALS_MACD4CDIV_C_BULL_20_50_9: "Classic Bullish",
  SIGNALS_MACD4CDIV_C_BEAR_20_50_9: "Classic Bearish",
  SIGNALS_MACD4CDIV_H_BULL_20_50_9: "Hidden Bullish",
  SIGNALS_MACD4CDIV_H_BEAR_20_50_9: "Hidden Bearish",
  SIGNALS_USCAN_IS_BUY_5: "Buy",
  SIGNALS_USCAN_IS_SELL_5: "Sell",
  SIGNALS_USCAN_ELAPSED_5: "Κεριά Πίσω",
  ...elSignalsGridCount,
  ...elSignalsGridFaq,
};
