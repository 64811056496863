/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import { TAppState } from "../../../../store/reducers";
import { AppDispatch } from "../../../../store/configureStore";
import {
  chartPeriodChanged,
  chartPairChanged,
  chartAnalysisChanged,
  chartActiveMetricsChanged,
  chartDatasetAnalysesChanged,
  chartDatasetPeriodsChanged,
  chartDatasetPairsChanged,
} from "../../../../store/reducers/analysis";
import { analysisSelector } from "../../../selectors";
import {
  useGetActivePeriodsQuery,
  useGetActivePairsQuery,
  useGetActiveAnalysesQuery,
} from "../../../../features/api";
import { filterGraphicalAnalyses } from "../../../../utilities/filters";
import { fetchCandles } from "../../../../store/actions/charts";
import { fetchAnalysisByPair } from "../../../../store/actions/analysis";
import { getPreactivated } from "./AnalysisChartConfig";

const AnalysisChartFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: pairData, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { data: analysisData, isLoading: analysesLoading } =
    useGetActiveAnalysesQuery();
  const {
    chart: {
      dataset: { periods, pairs, analyses },
      filters: { period, pair, analysis },
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  const periodSelected = (period: any) => {
    const analysisId = analysis.ID;
    const periodId = period.ID;
    const pairId = pair.ID;
    dispatch(chartPeriodChanged(period));
    const filtered = filterGraphicalAnalyses(analysisData, period);
    dispatch(chartDatasetAnalysesChanged(filtered));
    dispatch(chartAnalysisChanged(filtered ? first(filtered) : null));
    filtered.length === 0 &&
      dispatch(
        fetchCandles({
          period,
          pair,
        })
      );
    filtered.length >= 0 &&
      dispatch(
        fetchAnalysisByPair({
          analysisId,
          periodId,
          pairId,
        })
      );
  };

  const analysisSelected = (analysis: any) => {
    const analysisId = analysis.ID;
    const periodId = period.ID;
    const pairId = pair.ID;
    dispatch(chartAnalysisChanged(analysis));
    dispatch(chartActiveMetricsChanged(getPreactivated(analysis?.CODE)));
    dispatch(
      fetchAnalysisByPair({
        analysisId,
        periodId,
        pairId,
      })
    );
  };

  const pairSelected = (pair: any) => {
    dispatch(chartPairChanged(pair));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(chartDatasetPeriodsChanged(periodData));
      dispatch(chartPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (pairData && !pairsLoading) {
      dispatch(chartDatasetPairsChanged(pairData));
      dispatch(chartPairChanged(first(pairData)));
    }
  }, [dispatch, pairData, pairsLoading]);

  useEffect(() => {
    if (period && analysisData && !analysesLoading) {
      const filtered = filterGraphicalAnalyses(analysisData, period);
      dispatch(chartDatasetAnalysesChanged(filtered));
      dispatch(chartAnalysisChanged(first(filtered)));
    }
  }, [dispatch, period, analysisData, analysesLoading]);

  useEffect(() => {
    if (period && analysis && pair) {
      const analysisId = analysis?.ID;
      const periodId = period?.ID;
      const pairId = pair?.ID;
      dispatch(chartActiveMetricsChanged(getPreactivated(analysis?.CODE)));
      dispatch(
        fetchAnalysisByPair({
          analysisId,
          periodId,
          pairId,
        })
      );
    }
  }, [dispatch, pair]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginTop={2}
      marginBottom={2}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selected={[period?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={pairs}
          loading={pairsLoading}
          onChange={pairSelected}
          selected={[pair?.ID || ""]}
          selectKey="ID"
          displayKey="SYMBOL"
          inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
          label={t("PAIR_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          selectedDisplay={(row, key) =>
            key ? row[key].replaceAll(row.SYMBOL_PREFIX, "") : ""
          }
          optionsDisplay={(row, key) =>
            key ? row[key].replaceAll(row.SYMBOL_PREFIX, "") : ""
          }
          optionInfo={["TYPE_NAME", "PROVIDER_NAME"]}
          optionInfoStyle={{
            fontSize: "70%",
            color: "rgba(212,255,0,0.8)",
            paddingBottom: "1%",
          }}
          sortFn={(row: any) => [row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "")]}
          hasSearch
          searchKey="SYMBOL"
        />
      </Box>
      <Box>
        <BaseSelect
          data={analyses}
          loading={analysesLoading}
          onChange={analysisSelected}
          selectedDisplay={(row: any) =>
            t(`ANALYSIS_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)
          }
          optionsDisplay={(row: any) =>
            t(`ANALYSIS_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)
          }
          selected={[analysis?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("ANALYSIS_SELECT_INPUT_LABEL")}
          label={t("ANALYSIS_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
        />
      </Box>
    </Stack>
  );
};

export default AnalysisChartFilters;
