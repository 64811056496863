import React, { SyntheticEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import BugReportIcon from "@mui/icons-material/BugReport";
import { AppDispatch } from "../../../store/configureStore";
import { fetchSettings } from "../../../store/actions/user";
import LogsTab from "./Logs/LogsTab";
import BacktestingTab from "./Backtesting/BacktestingTab";

const AdminPage: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("logs");
  const dispatch = useDispatch<AppDispatch>();

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <Box>
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<DescriptionIcon />}
            iconPosition="start"
            value="logs"
            label={t("ADMIN_LOGS_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<BugReportIcon />}
            iconPosition="start"
            value="backtesting"
            label={t("OTHER_BACKTESTING_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "logs" && <LogsTab />}
        {value === "backtesting" && <BacktestingTab />}
      </Box>
    </Box>
  );
};

export default AdminPage;
