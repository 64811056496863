import React, { useEffect, useRef } from "react";
import i18n from "i18next";
import { createChart } from "lightweight-charts";
import { Box } from "@mui/material";
import { EChartDrawTypes } from "../../../constants/enums";
import { TChartCandles, TChartLines } from "../../../types/entities";
import LineLegend from "../ChartCommon/Legends/LineLegend";
import CandleLegend from "../ChartCommon/Legends/CandleLegend";
import AreaLegend from "../ChartCommon/Legends/AreaLegend";
import { sortMarkers } from "../../Common/helpers";
import {
  getCandleOptions,
  getCrosshair,
  getPriceScale,
  getTimeScale,
  getGridLine,
  getLayout,
  getWatermark,
  getOverlayPriceScale,
  getLocalization,
  getHandleScroll,
  getHandleScale,
  getKineticOptions,
  getTrackingModeOptions,
} from "../ChartCommon/Settings/ChartOptions";

type Props = {
  leftSymbol?: string;
  rightSymbol?: string;
  leftName?: string;
  rightName?: string;
  leftDrawType?: EChartDrawTypes;
  rightDrawType?: EChartDrawTypes;
  leftConfig?: any;
  rightConfig?: any;
  leftData?: TChartCandles | TChartLines;
  rightData?: TChartCandles | TChartLines;
  showLeftLegend?: boolean;
  showRightLegend?: boolean;
  leftLegendLabel?: string | null;
  rightLegendLabel?: string | null;
  leftMarkers?: any;
  rightMarkers?: any;
  height?: number | undefined;
  fullScreen?: boolean;
  fullScreenOffset?: number;
  autoSize?: boolean;
  horizontalLineConfig?: any;
  horizontalLineData?: TChartLines;
  fitContent?: boolean;
  autoDestroy?: boolean;
};

const CandleLineChart: React.FC<Props> = ({
  leftSymbol = "",
  rightSymbol = "",
  leftName = null,
  rightName = null,
  leftDrawType = EChartDrawTypes.Line,
  rightDrawType = EChartDrawTypes.Line,
  leftConfig = {},
  rightConfig = {},
  leftData = [],
  rightData = [],
  showLeftLegend = false,
  showRightLegend = true,
  leftLegendLabel = "",
  rightLegendLabel = "",
  leftMarkers = [],
  rightMarkers = [],
  height = 650,
  fullScreen = false,
  fullScreenOffset = 0,
  autoSize = true,
  horizontalLineConfig = {},
  horizontalLineData = [],
  fitContent = true,
}) => {
  const containerRef = useRef() as any;
  const chartRef = useRef() as any;
  const leftTitleLegendRef = useRef() as any;
  const rightTitleLegendRef = useRef() as any;
  const leftLegendRef = useRef() as any;
  const rightLegendRef = useRef() as any;

  useEffect(() => {
    if (chartRef.current) chartRef.current.remove();
    chartRef.current = createChart(containerRef.current, {
      width: containerRef.current.clientWidth,
      height: fullScreen ? window.innerHeight - fullScreenOffset : height,
      autoSize: fullScreen ? false : autoSize,
      watermark: getWatermark(
        i18n.t("COMMON_TRADING_CHART_WATERMARK") || "Dcatrader"
      ),
      layout: getLayout(),
      leftPriceScale: getPriceScale(leftData.length > 0),
      rightPriceScale: getPriceScale(rightData.length > 0),
      overlayPriceScales: getOverlayPriceScale(),
      timeScale: getTimeScale(),
      crosshair: getCrosshair(),
      grid: {
        horzLines: getGridLine("#334158"),
        vertLines: getGridLine("#334158"),
      },
      localization: getLocalization(),
      handleScroll: getHandleScroll(),
      handleScale: getHandleScale(),
      kineticScroll: getKineticOptions(),
      trackingMode: getTrackingModeOptions(),
    });
  });

  useEffect(() => {
    chartRef.current.subscribeCrosshairMove((params: any) => {
      if (leftLegendRef.current) {
        const { time } = params;
        switch (leftDrawType) {
          case EChartDrawTypes.Line:
          case EChartDrawTypes.Area:
          case EChartDrawTypes.Histogram:
            leftLegendRef.current.setLeftRightLegend(
              leftLegendLabel,
              leftData,
              time
            );
            break;
          case EChartDrawTypes.Candle:
            leftLegendRef.current.setLegend(leftData, time);
            break;
        }
      }
    });
  }, [leftDrawType, leftData, leftLegendLabel]);

  useEffect(() => {
    chartRef.current.subscribeCrosshairMove((params: any) => {
      if (rightLegendRef.current) {
        const { time } = params;
        switch (rightDrawType) {
          case EChartDrawTypes.Line:
          case EChartDrawTypes.Area:
          case EChartDrawTypes.Histogram:
            rightLegendRef.current.setLeftRightLegend(
              rightLegendLabel,
              rightData,
              time
            );
            break;
          case EChartDrawTypes.Candle:
            rightLegendRef.current.setLegend(rightData, time);
            break;
        }
      }
    });
  }, [rightDrawType, rightData, rightLegendLabel]);

  useEffect(() => {
    if (chartRef.current) {
      let series: any;
      if (leftDrawType === EChartDrawTypes.Line) {
        series = chartRef.current.addLineSeries(leftConfig);
      } else if (leftDrawType === EChartDrawTypes.Candle) {
        series = chartRef.current.addCandlestickSeries(
          leftConfig || getCandleOptions
        );
      } else if (leftDrawType === EChartDrawTypes.Area) {
        series = chartRef.current.addAreaSeries(leftConfig);
      } else if (leftDrawType === EChartDrawTypes.Histogram) {
        series = chartRef.current.addHistogramSeries(leftConfig);
      }
      series.setData(leftData);
      series.setMarkers(sortMarkers(leftMarkers));
      if (leftLegendRef.current) {
        switch (leftDrawType) {
          case EChartDrawTypes.Line:
          case EChartDrawTypes.Area:
          case EChartDrawTypes.Histogram:
            leftLegendRef.current.setLeftRightLegend(leftLegendLabel, leftData);
            break;
          case EChartDrawTypes.Candle:
            leftLegendRef.current.setLegend(leftData);
            break;
        }
      }
      fitContent && chartRef.current.timeScale().fitContent();
    }
  }, [
    leftDrawType,
    leftConfig,
    leftData,
    leftLegendLabel,
    leftMarkers,
    fitContent,
  ]);

  useEffect(() => {
    if (chartRef.current) {
      let series: any;
      if (rightDrawType === EChartDrawTypes.Line) {
        series = chartRef.current.addLineSeries(rightConfig);
      } else if (rightDrawType === EChartDrawTypes.Candle) {
        series = chartRef.current.addCandlestickSeries(
          rightConfig || getCandleOptions
        );
      } else if (rightDrawType === EChartDrawTypes.Area) {
        series = chartRef.current.addAreaSeries(rightConfig);
      } else if (rightDrawType === EChartDrawTypes.Histogram) {
        series = chartRef.current.addHistogramSeries(rightConfig);
      }
      series.setData(rightData);
      series.setMarkers(sortMarkers(rightMarkers));
      if (rightLegendRef.current) {
        switch (rightDrawType) {
          case EChartDrawTypes.Line:
          case EChartDrawTypes.Area:
          case EChartDrawTypes.Histogram:
            rightLegendRef.current.setLeftRightLegend(
              rightLegendLabel,
              rightData
            );
            break;
          case EChartDrawTypes.Candle:
            rightLegendRef.current.setLegend(rightData);
            break;
        }
      }

      chartRef.current.timeScale().fitContent();
    }
  }, [rightDrawType, rightConfig, rightData, rightLegendLabel, rightMarkers]);

  useEffect(() => {
    if (chartRef.current) {
      const horizontalSeries =
        chartRef.current.addLineSeries(horizontalLineConfig);
      horizontalSeries.setData(horizontalLineData);

      fitContent && chartRef.current.timeScale().fitContent();
    }
  }, [horizontalLineConfig, horizontalLineData, fitContent]);

  useEffect(() => {
    if (leftTitleLegendRef.current) {
      const current = leftTitleLegendRef.current;
      current.setTitleLegend(
        !leftName ? leftSymbol : `${leftSymbol} - ${leftName}`
      );
    }
    if (rightTitleLegendRef.current) {
      const current = leftTitleLegendRef.current;
      current.setTitleLegend(
        !rightName ? rightSymbol : `${rightSymbol} - ${rightName}`
      );
    }
  }, [leftName, leftSymbol, rightName, rightSymbol]);

  return (
    <Box>
      <Box
        sx={{
          fontFamily: `"Calibri", "Helvetica"`,
          fontWeight: "bold",
          padding: "0 0 1% 1%",
          fontSize: "85%",
          background: "#253248",
          color: "white",
        }}
      >
        {showLeftLegend && (
          <Box>
            {leftData.length !== 0 && leftDrawType === EChartDrawTypes.Line && (
              <LineLegend ref={leftLegendRef} />
            )}
            {leftData.length !== 0 &&
              leftDrawType === EChartDrawTypes.Candle && (
                <CandleLegend ref={leftLegendRef} />
              )}
            {leftData.length !== 0 && leftDrawType === EChartDrawTypes.Area && (
              <AreaLegend ref={leftLegendRef} />
            )}
          </Box>
        )}
        {showRightLegend && (
          <Box>
            {rightData.length !== 0 &&
              rightDrawType === EChartDrawTypes.Line && (
                <LineLegend ref={rightLegendRef} />
              )}
            {rightData.length !== 0 &&
              rightDrawType === EChartDrawTypes.Candle && (
                <CandleLegend ref={rightLegendRef} />
              )}
            {rightData.length !== 0 &&
              rightDrawType === EChartDrawTypes.Area && (
                <AreaLegend ref={rightLegendRef} />
              )}
          </Box>
        )}
      </Box>
      <Box ref={containerRef} />
    </Box>
  );
};

export default CandleLineChart;
