import React from "react";
import { Box } from "@mui/material";
import AnalysisGridFilters from "./AnalysisGridFilters";
import AnalysisGrid from "./AnalysisGrid";
import AnalysisGridFaq from "./AnalysisGridFaq";

const AnalysisGridTab: React.FC = () => {
  return (
    <Box>
      <AnalysisGridFilters />
      <AnalysisGrid />
      <AnalysisGridFaq />
    </Box>
  );
};

export default AnalysisGridTab;
