import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  log: any;
};

const LogItemDate: React.FC<Props> = ({ log }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 3 }}>
      <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {log?.OCCURED_AT}
      </Typography>
    </Box>
  );
};

export default LogItemDate;
