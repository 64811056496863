type TChartCandle = {
  time: any;
  open: number;
  close: number;
  high: number;
  low: number;
};

type TChartLine = {
  time: any;
  value: number;
};

type TChartMarker = {
  time: {
    year: number;
    month: number;
    day: number;
  };
  [key: string]: any;
};

export const AXIOS_STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

export type THttpResponseBody = {
  success: boolean;
  data: any;
  error: {
    code: number;
    message: number;
  } | null;
};

export type TTextAlign =
  | "start"
  | "end"
  | "left"
  | "right"
  | "center"
  | "justify"
  | "match-parent";

export type TChartCandles = Array<TChartCandle>;

export type TChartLines = Array<TChartLine>;

export type TChartMarkers = Array<TChartMarker>;

export type ChartJsDataset = {
  label?: string;
  data: Array<number>;
  borderColor?: string;
  background?: string;
  color?: string;
  borderWidth?: number;
};

export type TUserProfile = {
  userId: string;
  name: string;
  email: string;
  lastLogin: string | null;
  isAdmin: number;
};

export type TAppSettings = {
  authActive: boolean;
  forceTwoFactor: boolean;
};

export type TAppTheme = "light" | "dark" | string;

export type TAppLanguage = string;

export type TIndicatorConfig = {
  code: string;
  label: string;
  executions: Array<any>;
};

export type TIndicatorsConfig = {
  price: Array<TIndicatorConfig>;
  range: Array<TIndicatorConfig>;
  noRange: Array<TIndicatorConfig>;
  histogram: Array<TIndicatorConfig>;
  point: Array<TIndicatorConfig>;
};
