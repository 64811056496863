import React, { useTransition } from "react";
import { Box } from "@mui/material";
import {
  CloseFullscreenButton,
  OpenInFullButton,
} from "../../BaseButton/Buttons";

type Props = {
  dialogOpen: boolean;
  onDialogOpen?: () => void;
  onDialogClose?: () => void;
};

const TradingChartTopBarRight: React.FC<Props> = ({
  dialogOpen = false,
  onDialogOpen = () => null,
  onDialogClose = () => null,
}) => {
  const [, startTransition] = useTransition();
  return (
    <Box
      sx={{
        textAlign: "right",
        width: "50%",
      }}
    >
      {dialogOpen ? (
        <CloseFullscreenButton
          color="white"
          onClick={() => {
            startTransition(() => onDialogClose());
          }}
        />
      ) : (
        <OpenInFullButton
          color="white"
          onClick={() => {
            startTransition(() => onDialogOpen());
          }}
        />
      )}
    </Box>
  );
};

export default TradingChartTopBarRight;
