import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Box } from "@mui/material";
import logo from "./../../../assets/images/logo192.png";
import { AppDispatch } from "../../../store/configureStore";
import "../../../styles/Pages/Home/home.sass";
import { fetchSession } from "../../../store/actions/user";
import { getSettingsInStorage } from "../../../utilities/helpers";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const settings = getSettingsInStorage();

  useEffect(() => {
    if (settings) {
      const { authActive } = settings;
      if (authActive) dispatch(fetchSession());
    }
  });

  return (
    <Container>
      <Box sx={{ textAlign: "center", mt: 20 }}>
        <Box
          component="img"
          sx={{ height: 150, width: 150 }}
          src={logo}
          alt={t("APP_NAME")}
        />
      </Box>
    </Container>
  );
};

export default HomePage;
