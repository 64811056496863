import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

type Props = {
  value: number;
  color: string;
  width?: string;
  height?: number;
  variant?: "determinate" | "indeterminate" | "buffer" | "query" | undefined;
};

const BaseBar: React.FC<Props> = ({
  value,
  color,
  width = "100%",
  height = 20,
  variant = "determinate",
}) => {
  return (
    <Box sx={{ width }}>
      <LinearProgress
        value={value}
        color="inherit"
        sx={{ color, height }}
        variant={variant}
      />
    </Box>
  );
};

export default BaseBar;
