import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { signalSelector } from "../../../../selectors";
import { setIndicatorConfig } from "../../helpers";

type Props = {
  config: any;
};

const SignalsAnalysisIndicators: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation();
  const {
    analysis: { data },
  } = useSelector((state: TAppState) => signalSelector(state));

  const extendedConfig = setIndicatorConfig(config);

  return (
    <Box>
      {extendedConfig?.map((indicator: any, key: any) => (
        <Box key={key}>
          <Typography variant="h6" sx={{ paddingTop: 1 }}>
            {indicator.label}
          </Typography>
          {indicator.executions.map((execution: any, key: any) => (
            <Box
              key={key}
              sx={{
                display: "flex",
                padding: 0.5,
                marginTop: 1,
                border: "1px solid rgba(211,211,211,0.5)",
                borderRadius: "3px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "90%" }}>{execution.label}</Box>
              </Box>
              <Box
                sx={{
                  flex: 2,
                  display: window.innerWidth < 750 ? "block" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {execution.output.map((settings: any, key: any) => (
                  <Box key={key} sx={{ paddingLeft: 1, flex: 1 }}>
                    <Box
                      sx={{
                        color: settings.color,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {data && data[settings?.key]
                        ? `${data[settings?.key]} `
                        : t("SIGNALS_ANALYSIS_NO_DATA")}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box sx={{ flex: 1 }}>
                {execution.output.map((settings: any, key: any) =>
                  settings.label ? (
                    <Box key={key} sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          marginRight: 1,
                          background: settings.color,
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          MozBorderRadius: "50%",
                        }}
                      ></Box>
                      <Box>{settings.label}</Box>
                    </Box>
                  ) : (
                    <></>
                  )
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default SignalsAnalysisIndicators;
