import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Grid from "../../../Common/Grid/Grid";
import { TAppState } from "../../../../store/reducers";
import { analysisSelector } from "../../../selectors";
import getColumns, { gridKey, getVisibility } from "./AnalysisGridConfig";

const AnalysisGrid: React.FC = () => {
  const {
    list: {
      data,
      filters: { analysis },
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        columns={getColumns(analysis?.CODE)}
        data={data}
        visibility={getVisibility()}
      />
    </Box>
  );
};

export default AnalysisGrid;
