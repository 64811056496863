import React from "react";
import { Stack } from "@mui/material";
import ChartTopBarLeft from "./ChartTopBarLeft";
import ChartTopBarRight from "./ChartTopBarRight";

type Props = {
  dialogOpen: boolean;
  onDialogOpen?: () => void;
  onDialogClose?: () => void;
};

const TradingChartTopBar: React.FC<Props> = ({
  dialogOpen = false,
  onDialogOpen = () => null,
  onDialogClose = () => null,
}) => {
  return (
    <Stack
      sx={{
        background: "rgba(96, 130, 182, 0.6)",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <ChartTopBarLeft />
      <ChartTopBarRight
        dialogOpen={dialogOpen}
        onDialogOpen={onDialogOpen}
        onDialogClose={onDialogClose}
      />
    </Stack>
  );
};

export default TradingChartTopBar;
