import { SyntheticEvent } from "react";
import { Box, Typography } from "@mui/material";
import { CancelTextButton, OkTextButton } from "../BaseButton/Buttons";
import BaseDialog from "../BaseDialog/BaseDialog";

type Props = {
  title?: React.ReactNode;
  open: boolean;
  message: React.ReactNode;
  onCancel?: (e: SyntheticEvent) => void;
  onOk?: (e: SyntheticEvent) => void;
};

const BaseConfirmDialog: React.FC<Props> = ({
  title,
  open,
  message,
  onCancel,
  onOk,
}) => {
  const _onCancel = (event: SyntheticEvent) => onCancel && onCancel(event);
  const _onOk = (event: SyntheticEvent) => onOk && onOk(event);

  return (
    <BaseDialog
      open={open}
      title={<Box>{title}</Box>}
      content={<Typography>{message}</Typography>}
      actions={
        <Box>
          <CancelTextButton onClick={_onCancel} />
          <OkTextButton onClick={_onOk} />
        </Box>
      }
      contentPadding={3}
    />
  );
};

export default BaseConfirmDialog;
