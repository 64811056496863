import { createSlice } from "@reduxjs/toolkit";

export interface TBacktestingState {
  json: {
    candles: string;
    signals: string;
  };
  fullScreenOpen: boolean;
  uploadOpen: boolean;
}

const initialState: TBacktestingState = {
  json: {
    candles: "",
    signals: "",
  },
  fullScreenOpen: false,
  uploadOpen: false,
};

const backtestingSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    candlesJsonChanged: (state, action) => {
      state.json.candles = action.payload;
    },
    signalsJsonChanged: (state, action) => {
      state.json.signals = action.payload;
    },
    toggleFullScreen: (state, action) => {
      state.fullScreenOpen = action.payload;
    },
    toggleUpload: (state, action) => {
      state.uploadOpen = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  candlesJsonChanged,
  signalsJsonChanged,
  toggleFullScreen,
  toggleUpload,
} = backtestingSlice.actions;
export default backtestingSlice.reducer;
