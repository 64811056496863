import React from "react";
import { Box } from "@mui/material";
import SignalsGridFilters from "./SignalsGridFilters";
import SignalsGridCount from "./Count/SignalsGridCount";
import SignalsGridLegend from "./SignalsGridLegend";
import SignalsGrid from "./SignalsGrid";
import SignalsGridFaq from "./Faq/SignalsGridFaq";

const SignalsGridTab: React.FC = () => {
  return (
    <Box>
      <SignalsGridFilters />
      <SignalsGridCount />
      <SignalsGridLegend />
      <SignalsGrid />
      <SignalsGridFaq />
    </Box>
  );
};

export default SignalsGridTab;
