export const ROUTES = {
  WILDCARD: "*",
  HOME: "/",
  LOGIN: "/login",
  TFA: "/tfa",
  TFA_SETUP: "/tfa-setup",
  SIGNALS: "/signals",
  ANALYSIS: "/analysis",
  CHARTS: "/charts",
  ECONOMY: "/economy",
  OTHER: "/other",
  SETTINGS: "/settings",
  ADMIN: "/admin",
};
