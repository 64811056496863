import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Grid from "../../../Common/Grid/Grid";
import { TAppState } from "../../../../store/reducers";
import { signalSelector } from "../../../selectors";
import getColumns, {
  gridKey,
  getVisibility,
  getSignalRowClass,
} from "./SignalsGridConfig";
import { filterSignalData } from "../../../Common/helpers";

const SignalsGrid: React.FC = () => {
  const {
    list: {
      data,
      filters: { signal },
    },
  } = useSelector((state: TAppState) => signalSelector(state));
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        columns={[...getColumns(signal?.CODE)]}
        data={filterSignalData(signal?.CODE, data)}
        visibility={getVisibility()}
        getRowClassName={(params) => {
          const { row } = params;
          return getSignalRowClass(signal?.CODE, row);
        }}
      />
    </Box>
  );
};

export default SignalsGrid;
