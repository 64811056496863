import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_STATUSES } from "../../types/entities";
import { fetchSettings, updateSettings, verify } from "../actions/user";

export interface TSettingsState {
  twoFactor: {
    auth: {
      password: string;
    };
  };
  notifications: {
    emailPairs: boolean;
    emailSignals: boolean;
  };
  status: string | null;
  error: string | null;
}

const initialState: TSettingsState = {
  twoFactor: {
    auth: {
      password: "",
    },
  },
  notifications: {
    emailPairs: false,
    emailSignals: false,
  },
  status: null,
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verify.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        const { verified = false } = action.payload;
        if (!verified) state.twoFactor.auth.password = "";
      })
      .addCase(verify.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Verification failed";
        state.twoFactor.auth.password = "";
      })
      .addCase(fetchSettings.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        const { emailPairs, emailSignals } = action.payload;
        state.status = "idle";
        state.notifications.emailPairs = emailPairs;
        state.notifications.emailSignals = emailSignals;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      })
      .addCase(updateSettings.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        const { emailPairs, emailSignals } = action.payload;
        state.status = "idle";
        state.notifications.emailPairs = emailPairs;
        state.notifications.emailSignals = emailSignals;
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Update settings failed";
      });
  },
});

export default settingsSlice.reducer;
