import { Box } from "@mui/material";
import AnalysisChartFilters from "./AnalysisChartFilters";
import AnalysisChartLegends from "./AnalysisChartLegends";
import AnalysisChart from "./AnalysisChart";
import AnalysisChartFaq from "./AnalysisChartFaq";

const AnalysisChartTab: React.FC = () => {
  return (
    <Box>
      <AnalysisChartFilters />
      <AnalysisChartLegends />
      <AnalysisChart />
      <AnalysisChartFaq />
    </Box>
  );
};

export default AnalysisChartTab;
