import { AppModes } from "./enums";

export const APP = {
  MODE: AppModes.Production,
};

export const SNACKBAR_DURATION = 3000;

export const POLLING_MILLIS = 5000;

export const API = {
  URL: `https://api.dcatrader.com`,
  TIMEOUT: 30000,
  EXTENDED_TIMEOUT: 70000,
};

export const TWO_FACTOR_AUTH = {
  AUTH_CODE_LENGTH: 6,
};
