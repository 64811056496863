/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import { AppDispatch } from "../../../store/configureStore";
import BaseQrCode from "../../Common/BaseQrCode/BaseQrCode";
import { ContentCopyButton } from "../../Common/BaseButton/Buttons";
import BaseAuthCode from "../../Common/BaseAuthCode/BaseAuthCode";
import { enableTwoFactor } from "../../../store/actions/user";
import { ROUTES } from "../../../constants/routes";
import { TWO_FACTOR_AUTH } from "../../../constants/settings";

const TfaSetup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [authCode, setAuthCode] = useState("");

  const twoFactor = location?.state?.twoFactor;
  const secret = twoFactor?.secret;
  const uri = twoFactor?.uri;

  const onChange = (value: any) => {
    setAuthCode(value);
  };

  const onCodeComplete = (value: any) => {
    setAuthCode(value);
  };

  const handleSubmit = async () => {
    if (secret && authCode) {
      try {
        const setup = await dispatch(
          enableTwoFactor({ secret, authCode })
        ).unwrap();
        const { enabled = false } = setup;
        enabled && navigate(ROUTES.LOGIN);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    (!secret || !uri) && navigate(-1);
  }, []);

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography variant="h4">{t("TFA_SETUP_PAGE_HEADER")}</Typography>
      <Box sx={{ mt: 3, display: "grid", width: 500 }}>
        <Typography variant="h5" sx={{ mt: 2 }}>
          {t("TFA_SETUP_PAGE_STEP_1_HEADER")}
        </Typography>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography>{t("TFA_SETUP_PAGE_STEP_1_CONTENT")}</Typography>
        </Box>
        <Box sx={{ height: 250, width: 250, margin: "0px auto" }}>
          <BaseQrCode value={uri} />
        </Box>
      </Box>
      <Box sx={{ mt: 3, display: "grid", width: 500 }}>
        <Box sx={{ mt: 1, mb: 1 }}>
          <Typography>{t("TFA_SETUP_PAGE_STEP_1_TIP")}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", margin: "0px auto" }}>
          <Typography>{secret}</Typography>
          <ContentCopyButton
            sx={{ float: "right" }}
            onClick={() => navigator.clipboard.writeText(secret)}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 3, display: "grid", width: 500 }}>
        <Typography variant="h5" sx={{ mt: 2 }}>
          {t("TFA_SETUP_PAGE_STEP_2_HEADER")}
        </Typography>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography>{t("TFA_SETUP_PAGE_STEP_2_CONTENT")}</Typography>
        </Box>
        <Box sx={{ width: 400, mt: 3, mb: 5, margin: "0px auto" }}>
          <BaseAuthCode
            onComplete={onCodeComplete}
            onChange={onChange}
            length={TWO_FACTOR_AUTH.AUTH_CODE_LENGTH}
            onKeyUp={(e: any) =>
              e.key === "Enter" &&
              authCode.length === TWO_FACTOR_AUTH.AUTH_CODE_LENGTH &&
              handleSubmit()
            }
          />
        </Box>
      </Box>

      <Button
        variant="contained"
        sx={{ mt: 3, mb: 2, width: 300 }}
        onClick={handleSubmit}
      >
        {t("TFA_SETUP_PAGE_BUTTON")}
      </Button>
    </Box>
  );
};

export default TfaSetup;
