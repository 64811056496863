import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { includes, sortBy, first, groupBy } from "lodash";
import {
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  ListSubheader,
  InputAdornment,
  TextField,
  useScrollTrigger,
  Box,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  onChange: (row: any, event?: SelectChangeEvent) => any;
  onItemSelect?: any;
  multiselect?: boolean;
  disabled?: boolean;
  data?: any[];
  loading?: boolean;
  selectKey?: string;
  displayKey?: string;
  defaultValue?: string;
  label?: string | null | undefined;
  inputLabel?: string | null | undefined;
  minWidth?: string | number;
  maxWidth?: string | number;
  className?: string;
  selected?: Array<string>;
  itemHeight?: number;
  itemPaddingTop?: number;
  selectedDisplay?: (row: any, key: string) => string;
  optionsDisplay?: (row: any, key: string) => string;
  optionInfo?: string[];
  optionInfoStyle?: any;
  optionInfoSeparator?: string;
  groupKey?: string;
  headerKey?: string;
  sortFn?: (row: any) => any | string[] | [];
  hasSearch?: boolean;
  searchKey?: string;
  menuZIndex?: number;
  limitOptions?: number;
};

const BaseSelect: React.FC<Props> = ({
  onChange = (row: any, event: SelectChangeEvent) => null,
  onItemSelect = (on: boolean, item: any) => item,
  multiselect = false,
  disabled = false,
  data = [],
  selected = [],
  loading = false,
  selectKey = "",
  displayKey = "",
  groupKey = "",
  headerKey = "",
  /* multiselect:`Array<string>` single: `string` */
  defaultValue = "",
  label = "",
  inputLabel = "",
  minWidth = 100,
  maxWidth,
  className = "",
  itemHeight = 48,
  itemPaddingTop = 8,
  selectedDisplay = (row, key) => row[key],
  optionsDisplay = (row, key) => row[key],
  optionInfo = [],
  optionInfoStyle = {},
  optionInfoSeparator = "-",
  sortFn = (row: any) => [],
  hasSearch = false,
  searchKey = "",
  menuZIndex = 1000000000,
  /* limited options works only if search is open */
  limitOptions = 300,
}) => {
  const { t } = useTranslation();
  const scrollTrigger = useScrollTrigger();
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const _onChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    /* multiselect:`Array<string>` single: `string` */
    const result = data.filter((b: any) =>
      multiselect ? includes(value, b[selectKey]) : b[selectKey] === value
    );
    onChange(multiselect ? result : first(result), event);
  };

  const _onOpen = () => {
    setIsOpen(true);
  };

  const _onClose = () => {
    setSearchText("");
    setIsOpen(false);
  };

  const displayData = useMemo(() => {
    const sortOptions = (options: any) =>
      sortBy(options, (row: any) => sortFn(row));
    const result = data.filter((row) =>
      hasSearch && searchText !== ""
        ? row[searchKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1
        : true
    );
    if (groupKey === "") {
      return sortOptions(result);
    } else {
      const grouped = groupBy(result, (row: any) => row[groupKey]);
      return Object.keys(grouped).map((key) => sortOptions(grouped[key]));
    }
  }, [data, groupKey, hasSearch, searchKey, searchText, sortFn]);

  const getMenuItem = (row: any, select_key: string, display_key: string) => (
    <MenuItem
      onClick={() => onItemSelect(includes(selected, row[select_key]), row)}
      value={row[select_key]}
      key={`menu_item_${row[select_key]}`}
      sx={{ maxWidth }}
    >
      {multiselect ? (
        <>
          <Checkbox checked={includes(selected, row[select_key])} />
          <ListItemText primary={optionsDisplay(row, display_key)} />
        </>
      ) : (
        <Box>
          <Typography>{optionsDisplay(row, display_key)}</Typography>
          <Typography sx={optionInfoStyle}>
            {optionInfo.map((oi) => row[oi]).join(` ${optionInfoSeparator} `)}
          </Typography>
        </Box>
      )}
    </MenuItem>
  );

  const _render = (selected: any) =>
    data
      .filter((r) =>
        multiselect
          ? includes(selected, r[selectKey])
          : selected === r[selectKey]
      )
      .map((s: any) => selectedDisplay(s, displayKey))
      .join(", ");

  useEffect(() => {
    scrollTrigger && _onClose();
  }, [scrollTrigger]);

  return (
    <FormControl size="small" fullWidth>
      {!loading && (
        <>
          <InputLabel id="select-label">{inputLabel}</InputLabel>
          <Select
            sx={{ minWidth, maxWidth }}
            disabled={disabled}
            className={className}
            label={label}
            labelId="select-label"
            open={isOpen}
            onChange={_onChange}
            onOpen={_onOpen}
            onClose={_onClose}
            /*
             * The `value` prop must be an array when using
             * the `Select` component with `multiple`
             * value is mandatory when component is controlled
             */
            value={multiselect ? selected : first(selected)}
            /*
             * defaultValue is mandatory when component is not controlled
             */
            defaultValue={defaultValue}
            input={<OutlinedInput label="Tag" />}
            multiple={multiselect}
            renderValue={(selected) => _render(selected)}
            MenuProps={{
              autoFocus: !hasSearch,
              style: {
                zIndex: menuZIndex,
              },
              PaperProps: {
                sx: {
                  maxHeight: itemHeight * 4.5 + itemPaddingTop,
                  minWidth,
                },
              },
            }}
          >
            {hasSearch && data.length > 0 && data.length > limitOptions && (
              <Box sx={{ padding: 1, fontSize: "75%", maxWidth }}>
                {t("BASE_SELECT_DISPLAY_LIMIT_MESSAGE", {
                  limit: limitOptions,
                })}
              </Box>
            )}
            {hasSearch && (
              <TextField
                size="small"
                autoFocus
                sx={{ padding: "2%", maxWidth }}
                placeholder={
                  t("BASE_SELECT_SEARCH_PLACEHOLDER") || "Type to search..."
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    /* Prevents autoselect while typing */
                    e.stopPropagation();
                  }
                }}
              />
            )}
            {displayData.length > 0 &&
              (hasSearch
                ? displayData.slice(0, limitOptions)
                : displayData
              ).map((s: any) => {
                return groupKey === ""
                  ? [
                      headerKey !== "" && (
                        <ListSubheader
                          key={`header_${s[selectKey]}`}
                          sx={{ maxWidth }}
                        >
                          <Typography>{s[headerKey]}</Typography>
                        </ListSubheader>
                      ),
                      getMenuItem(s, selectKey, displayKey),
                    ]
                  : [
                      headerKey !== "" && (
                        <ListSubheader
                          key={`header_${s[selectKey]}`}
                          sx={{ maxWidth }}
                        >
                          <Typography>{first(s)[headerKey]}</Typography>
                        </ListSubheader>
                      ),
                      ...s.map((gs: any) =>
                        getMenuItem(gs, selectKey, displayKey)
                      ),
                    ];
              })}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default BaseSelect;
