import React, { Ref, useImperativeHandle, useState } from "react";
import { Box, Typography } from "@mui/material";

const TitleLegend = React.forwardRef(
  (props, ref: Ref<{ setTitleLegend: any }>) => {
    const [legend, setLegend] = useState<string>("");

    useImperativeHandle(ref, () => ({ setTitleLegend }));

    const setTitleLegend: (title: string) => void = (title) => {
      setLegend(title);
    };

    return (
      <Box sx={{ height: 20 }}>
        <Typography>{legend}</Typography>
      </Box>
    );
  }
);

export default TitleLegend;
