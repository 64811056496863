export const enEconomyPage = {
  ECONOMY_CRISIS_TAB_LABEL: "Crisis",
  ECONOMY_ECONOMY_TAB_LABEL: "Economy",
  ECONOMY_CONSUMER_TAB_LABEL: "Consumer",
  ECONOMY_OTHER_TAB_LABEL: "Other",
  ECONOMY_INDEX_DESCRIPTION_INDPRO:
    "The industrial production (IP) index measures the real output of all relevant establishments located in the United States, regardless of their ownership, but not those located in U.S. territories.",
  ECONOMY_INDEX_DESCRIPTION_STLFSI4:
    "The index measures the degree of financial stress in the markets. Zero is viewed as representing normal financial market conditions. Values below zero suggest below-average, while values above zero suggest above-average financial market stress.",
  ECONOMY_INDEX_DESCRIPTION_NFCI:
    "The Chicago Fed National Financial Conditions Index (NFCI) measures the overall financial conditions in the United States. Values above zero, suggest that financial conditions are relatively tighter. This means that borrowing costs may be higher, credit availability may be limited and market volatility may be elevated.",
  ECONOMY_INDEX_DESCRIPTION_RECPROUSM156N:
    "Smoothed recession probabilities are obtained from a dynamic-factor model applied to four monthly variables: non-farm payroll employment, the index of industrial production, real personal income, real manufacturing and trade sales. When value exceeds 5%, history suggests that the recession probability increases significantly.",
  ECONOMY_INDEX_DESCRIPTION_SAHMREALTIME:
    "Sahm Recession Indicator signals the start of a recession when the three-month moving average of the national unemployment rate rises by 0.50 percentage points or more relative to it's low during the previous 12 months. This indicator is based on real time data, that is, the unemployment rate that were available in a given month.",
  ECONOMY_INDEX_DESCRIPTION_T10Y2YM:
    "The 10 Year Treasury Constant Maturity Minus 2 Year Treasury Constant Maturity measures the spread between the 10 Year Treasury Constant Maturity and the 2 Year Treasury Constant Maturity. This spread is used from bond market investors to consider how monetary policy will impact future markets.",
  ECONOMY_INDEX_DESCRIPTION_T10Y3MM:
    "The 10 Year Treasury Constant Maturity Minus 3 Year Treasury Constant Maturity measures the spread between the 10 Year Treasury Constant Maturity and the 3 Year Treasury Constant Maturity. This spread is used as the base price of short-term borrowing and is a tool that shows if U.S. economy balanced.",
  ECONOMY_INDEX_DESCRIPTION_FYGDP:
    "The gross domestic product is the total market value of all the finished goods and services produced within U.S.A. borders (billion dollars).",
  ECONOMY_INDEX_DESCRIPTION_JTSJOL:
    "The total nonfarm job openings measures all jobs that are not filled on the last business day of the month. A job is considered open if a specific position exists and there is work available for it, can be started within 30 days and there is active recruiting for the position.",
  ECONOMY_INDEX_DESCRIPTION_TOTDTEUSQ163N:
    "The total debt to equity is calculated by using debt as the numerator and capital and reserves as the denominator. It is a measure of corporate leverage the extent to which activities are financed out of own funds.",
  ECONOMY_INDEX_DESCRIPTION_DTWEXBGS:
    "The nominal broad U.S. dollar index is a measure of the value of the U.S. dollar relative to a basket of foreign currencies that are U.S.A.'s most significant trading partners.",
  ECONOMY_INDEX_DESCRIPTION_IGREA:
    "The global real economic activity index is an updated index of global real economic activity in industrial commodity markets. This business-cycle index is expressed in percent deviations from trend.",
  ECONOMY_INDEX_DESCRIPTION_M1SL:
    "M1 is a narrow measure of the money supply that includes currency, demand deposits, and other liquid deposits, including savings deposits. M1 does not include financial assets, such as bonds.",
  ECONOMY_INDEX_DESCRIPTION_M2SL:
    "M2 is a measure of the money supply that includes cash, checking deposits, and other deposits readily convertible to cash, such as CDs. M2 does not include large institutional cash deposits.",
  ECONOMY_INDEX_DESCRIPTION_TOTALSL:
    "The total consumer credit owned and securitized is a monthly sample estimate of loans to households, for financing consumer purchases of goods and services, for refinancing existing consumer debt, but not mortgages (billion dollars).",
  ECONOMY_INDEX_DESCRIPTION_CCLACBW027SBOG:
    "The consumer loans (credit cards and other revolving plans, all commercial banks) is an indicator of how much consumers owe to commercial banks (billion dollars).",
  ECONOMY_INDEX_DESCRIPTION_PMSAVE:
    "The personal saving measures the amount of consumer personal saving. It is an indicator of how much consumer income is disposable for goods or services (billion dollars).",
  ECONOMY_INDEX_DESCRIPTION_UMCSENT:
    "The University of Michigan Consumer Sentiment Index is a consumer confidence index published monthly by the University of Michigan. The index is normalized to have a value of 100 in the first quarter of 1966.",
  ECONOMY_YIELDS_3M: "3-month",
  ECONOMY_YIELDS_6M: "6-month",
  ECONOMY_YIELDS_1Y: "1-year",
  ECONOMY_YIELDS_2Y: "2-year",
  ECONOMY_YIELDS_3Y: "3-year",
  ECONOMY_YIELDS_5Y: "5-year",
  ECONOMY_YIELDS_7Y: "7-year",
  ECONOMY_YIELDS_10Y: "10-year",
  ECONOMY_YIELDS_20Y: "20-year",
  ECONOMY_YIELDS_30Y: "30-year",
  ECONOMY_YIELD_CHART_TITLE: "Interest Rate Yield Curve",
  ECONOMY_YIELD_CHART_DESCRIPTION:
    "A yield curve is a line that plots yields, or interest rates, of bonds that have equal credit quality but differing maturity dates. The slope of the yield curve can predict future interest rate changes and economic activity. A so-called normal shape for the yield curve is where short-term yields are lower than long-term yields, so the yield curve slopes upward. An inverted shape for the yield curve is where short-term yields are higher than long-term yields, so the yield curve slopes downward. A flat shape for the yield curve occurs when short-term yields are similar to long-term yields.",
  ECONOMY_INFLATION_LABEL_1Y: "Expected Inflation 1 year",
  ECONOMY_INFLATION_LABEL_2Y: "Expected Inflation 2 years",
  ECONOMY_INFLATION_LABEL_5Y: "Expected Inflation 5 years",
  ECONOMY_INDEX_DESCRIPTION_EXPINF1YR:
    "The expected inflation rate in one year. It measures the expected rate of inflation along with the inflation risk premium, the real risk premium, and the real interest rate.",
  ECONOMY_INDEX_DESCRIPTION_EXPINF2YR:
    "The expected inflation rate in two years. It measures the expected rate of inflation along with the inflation risk premium, the real risk premium, and the real interest rate.",
  ECONOMY_INDEX_DESCRIPTION_EXPINF5YR:
    "The expected inflation rate in five years. It measures the expected rate of inflation along with the inflation risk premium, the real risk premium, and the real interest rate.",
  ECONOMY_INFLATION_CHART_DESCRIPTION:
    "The chart shows the expected rate of inflation over the next years along with the inflation risk premium, the real risk premium, and the real interest rate. The estimates are calculated with a model that uses treasury yields, inflation data, inflation swaps and survey-based measures of inflation expectations.",
};

export const elEconomyPage = {
  ECONOMY_CRISIS_TAB_LABEL: "Κρίσεις",
  ECONOMY_ECONOMY_TAB_LABEL: "Οικονομία",
  ECONOMY_CONSUMER_TAB_LABEL: "Καταναλωτής",
  ECONOMY_OTHER_TAB_LABEL: "Άλλα",
  ECONOMY_INDEX_DESCRIPTION_INDPRO:
    "Ο βιομηχανικός δείκτης παραγωγής (IP) μετρά το πραγματικό προϊόν παραγωγής όλων των μονάδων εντός των Ηνωμένων Πολιτειών, ανεξάρτητα από την ιδιοκτησία τους, αλλά όχι αυτών που βρίσκονται σε κτήσεις των Ηνωμένων Πολιτειών.",
  ECONOMY_INDEX_DESCRIPTION_STLFSI4:
    "Ο δείκτης μετρά τον βαθμό της οικονομικής πίεσης στις αγορές. Η τιμή μηδέν αναπαριστά κανονικές συνθήκες στις αγορές. Τιμές κάτω από το μηδέν συμβολίζουν συνθήκες κάτω του μέσου, ενώ τιμές πάνω από το μηδέν συμβολίζουν συνθήκες πάνω από το μέσο.",
  ECONOMY_INDEX_DESCRIPTION_NFCI:
    "O δείκτης Chicago Fed National Financial Conditions (NFCI) μετρά τις συνολικές οικονομικές συνθήκες στις Ηνωμένες Πολιτείες. Τιμές κάτω από το μηδέν υποδεικνύουν ότι οι οικονομικές συνθήκες είναι σχετικά πιο σφιχτές. Αυτό σημαίνει ότι το κόστος δανεισμού ενδέχεται να είναι υψηλότερο, η διαθεσιμότητα πίστωσης περιορισμένη και ότι ενδέχεται να ανέβει η μεταβλητότητα των αγορών.",
  ECONOMY_INDEX_DESCRIPTION_RECPROUSM156N:
    "Οι πιθανότητες εξομάλυνσης της ύφεσης προκύπτουν από ένα μοντέλο δυναμικού παράγοντα που εφαρμόζεται σε τέσσερις μηνιαίες μεταβλητές: μισθοδοσία εκτός των γεωργικών εκμεταλλεύσεων, δείκτης βιομηχανικής παραγωγής, πραγματικό προσωπικό εισόδημα, πραγματική μεταποίηση και εμπορικές πωλήσεις. Όταν η τιμή υπερβαίνει το 5%, η ιστορία υποδηλώνει ότι η πιθανότητα ύφεσης αυξάνεται σημαντικά.",
  ECONOMY_INDEX_DESCRIPTION_SAHMREALTIME:
    "Ο δείκτης ύφεσης Sahm σηματοδοτεί την έναρξη μιας ύφεσης όταν ο κινητός μέσος όρος τριών μηνών του εθνικού ποσοστού ανεργίας αυξάνεται κατά 0,50 ποσοστιαίες μονάδες ή περισσότερο σε σχέση με το χαμηλό του κατά τους προηγούμενους 12 μήνες. Αυτός ο δείκτης βασίζεται σε δεδομένα σε πραγματικό χρόνο, δηλαδή στο ποσοστό ανεργίας που ήταν διαθέσιμο σε έναν δεδομένο μήνα.",
  ECONOMY_INDEX_DESCRIPTION_T10Y2YM:
    "Ο δείκτης μετρά τη διαφορά μεταξύ της σταθερής ληκτότητας 10-ετών ομολόγων και της σταθερής ληκτότητας 2-ετών ομολόγων των Ηνωμένων Πολιτειών. Αυτή η διαφορά χρησιμοποιείται από επενδυτές της αγοράς ομολόγων για να εξετάσουν πώς η νομισματική πολιτική θα επηρεάσει τις μελλοντικές αγορές.",
  ECONOMY_INDEX_DESCRIPTION_T10Y3MM:
    "Ο δείκτης μετρά τη διαφορά μεταξύ της σταθερής ληκτότητας 10-ετών ομολόγων και της σταθερής ληκτότητας 3-ετών ομολόγων των Ηνωμένων Πολιτειών. Αυτό το spread χρησιμοποιείται ως η τιμή βάσης του βραχυπρόθεσμου δανεισμού και είναι ένα εργαλείο που δείχνει εάν η οικονομία των Ηνωμένων Πολιτειών είναι ισορροπημένη.",
  ECONOMY_INDEX_DESCRIPTION_FYGDP:
    "Το ακαθάριστο εγχώριο προϊόν είναι η συνολική αγοραία αξία όλων των τελικών αγαθών και υπηρεσιών που παράγονται εντός των συνόρων των ΗΠΑ (δισεκατομμύρια δολάρια).",
  ECONOMY_INDEX_DESCRIPTION_JTSJOL:
    "Οι συνολικές θέσεις εργασίας εκτός γεωργικών εκμεταλλεύσεων μετρά όλες τις θέσεις εργασίας που δεν έχουν καλυφθεί την τελευταία εργάσιμη ημέρα του μήνα. Μια θέση εργασίας θεωρείται ανοιχτή εάν υπάρχει συγκεκριμένη θέση και υπάρχει διαθέσιμη εργασία για αυτήν, μπορεί να ξεκινήσει εντός 30 ημερών και υπάρχει ενεργή πρόσληψη για τη θέση.",
  ECONOMY_INDEX_DESCRIPTION_TOTDTEUSQ163N:
    "Το συνολικό χρέος προς τα ίδια κεφάλαια υπολογίζεται χρησιμοποιώντας το χρέος ως αριθμητή και το κεφάλαιο και τα αποθεματικά ως παρονομαστή. Είναι ένα μέτρο της εταιρικής μόχλευσης στο πρίσμα που οι δραστηριότητες χρηματοδοτούνται από ίδια κεφάλαια.",
  ECONOMY_INDEX_DESCRIPTION_DTWEXBGS:
    "Ο ονομαστικός ευρύς δείκτης δολαρίου ΗΠΑ είναι ένα μέτρο της αξίας του δολαρίου ΗΠΑ σε σχέση με ένα καλάθι ξένων νομισμάτων που είναι οι σημαντικότεροι εμπορικοί εταίροι των Ηνωμένων Πολιτειών.",
  ECONOMY_INDEX_DESCRIPTION_IGREA:
    "Ο παγκόσμιος δείκτης πραγματικής οικονομικής δραστηριότητας είναι ένας ενημερωμένος δείκτης της παγκόσμιας πραγματικής οικονομικής δραστηριότητας στις αγορές βιομηχανικών εμπορευμάτων. Αυτός ο δείκτης οικονομικού κύκλου εκφράζεται σε ποσοστιαίες αποκλίσεις από την τάση.",
  ECONOMY_INDEX_DESCRIPTION_M1SL:
    "Το M1 είναι ένα στενό μέτρο της προσφοράς χρήματος που περιλαμβάνει νόμισμα, καταθέσεις όψεως και άλλες καταθέσεις ρευστότητας, συμπεριλαμβανομένων των καταθέσεων ταμιευτηρίου. Το Μ1 δεν περιλαμβάνει χρηματοοικονομικά περιουσιακά στοιχεία, όπως ομόλογα.",
  ECONOMY_INDEX_DESCRIPTION_M2SL:
    "Το M2 είναι ένα μέτρο της προσφοράς χρήματος που περιλαμβάνει μετρητά, καταθέσεις σε έλεγχο και άλλες καταθέσεις άμεσα μετατρέψιμες σε μετρητά, όπως CD. Το Μ2 δεν περιλαμβάνει μεγάλες θεσμικές καταθέσεις μετρητών.",
  ECONOMY_INDEX_DESCRIPTION_TOTALSL:
    "Η συνολική καταναλωτική πίστη που κατέχεται και τιτλοποιείται είναι μια μηνιαία δειγματοληπτική εκτίμηση δανείων προς νοικοκυριά, για χρηματοδότηση καταναλωτικών αγορών αγαθών και υπηρεσιών, για αναχρηματοδότηση υφιστάμενου καταναλωτικού χρέους, αλλά όχι στεγαστικών δανείων (δισεκατομμύρια δολάρια).",
  ECONOMY_INDEX_DESCRIPTION_CCLACBW027SBOG:
    "Τα καταναλωτικά δάνεια (πιστωτικές κάρτες και άλλα ανανεούμενα προγράμματα, όλες οι εμπορικές τράπεζες) είναι ένας δείκτης του πόσα οφείλουν οι καταναλωτές στις εμπορικές τράπεζες (δισεκατομμύρια δολάρια).",
  ECONOMY_INDEX_DESCRIPTION_PMSAVE:
    "Η προσωπική αποταμίευση μετρά το ποσό της προσωπικής αποταμίευσης του καταναλωτή. Είναι ένας δείκτης του πόσο εισόδημα των καταναλωτών είναι διαθέσιμο για αγαθά ή υπηρεσίες (δισεκατομμύρια δολάρια).",
  ECONOMY_INDEX_DESCRIPTION_UMCSENT:
    "Ο δείκτης καταναλωτικού συναισθήματος του πανεπιστημίου του Μίσιγκαν είναι ένας δείκτης καταναλωτικής εμπιστοσύνης που δημοσιεύεται κάθε μήνα από το Πανεπιστήμιο του Μίσιγκαν. Ο δείκτης κανονικοποιείται ώστε να έχει τιμή 100 το πρώτο τρίμηνο του 1966.",
  ECONOMY_YIELDS_3M: "3-μηνών",
  ECONOMY_YIELDS_6M: "6-μηνών",
  ECONOMY_YIELDS_1Y: "1-έτους",
  ECONOMY_YIELDS_2Y: "2-ετών",
  ECONOMY_YIELDS_3Y: "3-ετών",
  ECONOMY_YIELDS_5Y: "5-ετών",
  ECONOMY_YIELDS_7Y: "7-ετών",
  ECONOMY_YIELDS_10Y: "10-ετών",
  ECONOMY_YIELDS_20Y: "20-ετών",
  ECONOMY_YIELDS_30Y: "30-ετών",
  ECONOMY_YIELD_CHART_TITLE: "Καμπύλη Επιτοκίων",
  ECONOMY_YIELD_CHART_DESCRIPTION:
    "Η καμπύλη επιτοκίων είναι μια γραμμή που απεικονίζει τις αποδόσεις ή τα επιτόκια ομολόγων που έχουν ίση πιστωτική ποιότητα αλλά διαφορετικές ημερομηνίες λήξης. Η κλίση της καμπύλης αποδόσεων μπορεί να προβλέψει τις μελλοντικές μεταβολές των επιτοκίων και την οικονομική δραστηριότητα. Το λεγόμενο κανονικό σχήμα για την καμπύλη απόδοσης είναι όπου οι βραχυπρόθεσμες αποδόσεις είναι χαμηλότερες από τις μακροπρόθεσμες αποδόσεις, επομένως η καμπύλη απόδοσης κλίνει προς τα πάνω. Ένα ανεστραμμένο σχήμα για την καμπύλη απόδοσης είναι όπου οι βραχυπρόθεσμες αποδόσεις είναι υψηλότερες από τις μακροπρόθεσμες αποδόσεις, επομένως η καμπύλη απόδοσης κλίνει προς τα κάτω. Ένα επίπεδο σχήμα για την καμπύλη απόδοσης εμφανίζεται όταν οι βραχυπρόθεσμες αποδόσεις είναι παρόμοιες με τις μακροπρόθεσμες αποδόσεις.",
  ECONOMY_INFLATION_LABEL_1Y: "Expected Inflation in 1 year",
  ECONOMY_INFLATION_LABEL_2Y: "Expected Inflation in 2 years",
  ECONOMY_INFLATION_LABEL_5Y: "Expected Inflation in 5 years",
  ECONOMY_INDEX_DESCRIPTION_EXPINF1YR:
    "Ο αναμενόμενος ρυθμός πληθωρισμού σε ένα έτος. Μετρά τον αναμενόμενο ρυθμό πληθωρισμού μαζί με το ασφάλιστρο κινδύνου πληθωρισμού, το ασφάλιστρο πραγματικού κινδύνου και το πραγματικό επιτόκιο.",
  ECONOMY_INDEX_DESCRIPTION_EXPINF2YR:
    "Ο αναμενόμενος ρυθμός πληθωρισμού σε δύο έτη. Μετρά τον αναμενόμενο ρυθμό πληθωρισμού μαζί με το ασφάλιστρο κινδύνου πληθωρισμού, το ασφάλιστρο πραγματικού κινδύνου και το πραγματικό επιτόκιο.",
  ECONOMY_INDEX_DESCRIPTION_EXPINF5YR:
    "Ο αναμενόμενος ρυθμός πληθωρισμού σε πέντε έτη. Μετρά τον αναμενόμενο ρυθμό πληθωρισμού μαζί με το ασφάλιστρο κινδύνου πληθωρισμού, το ασφάλιστρο πραγματικού κινδύνου και το πραγματικό επιτόκιο.",
  ECONOMY_INFLATION_CHART_DESCRIPTION:
    "Το διάγραμμα δείχνει τον αναμενόμενο ρυθμό πληθωρισμού τα επόμενα χρόνια μαζί με το ασφάλιστρο κινδύνου πληθωρισμού, το ασφάλιστρο πραγματικού κινδύνου και το πραγματικό επιτόκιο. Οι εκτιμήσεις υπολογίζονται με ένα μοντέλο που χρησιμοποιεί αποδόσεις του δημοσίου, δεδομένα πληθωρισμού, ανταλλαγές πληθωρισμού και μετρήσεις πληθωριστικών προσδοκιών που βασίζονται σε έρευνες.",
};
