import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";

type Props = {
  checked?: boolean | undefined;
  defaultChecked?: boolean | undefined;
  disabled?: boolean | undefined;
  label?: string;
  labelPlacement?: "end" | "start" | "top" | "bottom" | undefined;
  onChange?: (active: boolean, event: any) => void;
};

const BaseSwitch: React.FC<Props> = ({
  checked,
  defaultChecked,
  disabled,
  onChange = (active: boolean, event?: any) => null,
  label = "",
  labelPlacement = "end",
}) => {
  const [switchChecked, setSwitchChecked] = useState(checked);
  const [switchDisabled, setSwitchDisabled] = useState(disabled);

  const _onChange = (event: any) => onChange(event.target.checked, event);

  useEffect(() => {
    setSwitchChecked(checked);
  }, [checked]);

  useEffect(() => {
    setSwitchDisabled(disabled);
  }, [disabled]);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={switchChecked}
          defaultChecked={defaultChecked}
          disabled={switchDisabled}
          color="primary"
          onChange={(event: any) => _onChange(event)}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

export default BaseSwitch;
