import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { AppDispatch } from "../../../../../store/configureStore";
import {
  DeleteButton,
  LaunchButton,
} from "../../../../Common/BaseButton/Buttons";
import { UserRoles } from "../../../../../constants/enums";
import { getUserInStorage } from "../../../../../utilities/helpers";
import BaseDialog from "../../../../Common/BaseDialog/BaseDialog";
import BaseConfirmDialog from "../../../../Common/BaseConfirmDialog/BaseConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { deleteAppLog } from "../../../../../store/actions/logs";
import { TAppState } from "../../../../../store/reducers";
import { adminSelector } from "../../../../selectors";
import { listLogsSet } from "../../../../../store/reducers/admin";
import LogItemDetailsTopBar from "./Details/LogItemDetailsTopBar";
import LogItemDetails from "./Details/LogItemDetails";

type Props = {
  log: any;
};

const LogItemActions: React.FC<Props> = ({ log }) => {
  const profileData = getUserInStorage();
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    list: { all },
  } = useSelector((state: TAppState) => adminSelector(state));

  const onDelete = async () => {
    const filtered = all.filter((row: any) => row.ID !== log.ID);
    const data = await dispatch(deleteAppLog({ id: log.ID })).unwrap();
    data.length === 0 && dispatch(listLogsSet(filtered));
    data.length === 0 && setDeleteOpen(false);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      <>
        <BaseDialog
          open={detailsOpen}
          fullScreen
          padding={2}
          content={
            <>
              <LogItemDetailsTopBar onClose={() => setDetailsOpen(false)} />
              <LogItemDetails log={log} />
            </>
          }
        />
        <LaunchButton onClick={() => setDetailsOpen(true)} />
      </>
      {profileData?.role === UserRoles.Admin && (
        <>
          <BaseConfirmDialog
            title={t("ADMIN_LOG_ITEM_ACTION_DELETE_TITLE")}
            open={deleteOpen}
            message={t("ADMIN_LOG_ITEM_ACTION_DELETE_MESSAGE")}
            onCancel={() => setDeleteOpen(false)}
            onOk={() => onDelete()}
          />
          <DeleteButton onClick={() => setDeleteOpen(true)} />
        </>
      )}
    </Box>
  );
};

export default LogItemActions;
