import * as React from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

type Props = TransitionProps & {
  children: React.ReactElement;
};

const Transition = React.forwardRef((props: Props, ref: React.Ref<unknown>) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Transition;
