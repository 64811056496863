import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Tabs, Tab } from "@mui/material";
import "../../../styles/Pages/Other/other.sass";
import RankingTab from "./Ranking/RankingTab";
import FearGreedTab from "./FearGreed/FearGreedTab";

const OtherPage: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("ranking");

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  return (
    <Box className="other-page">
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<MilitaryTechIcon />}
            iconPosition="start"
            value="ranking"
            label={t("OTHER_RANKINGS_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<SpeedIcon />}
            iconPosition="start"
            value="feargreed"
            label={t("OTHER_FEARGREED_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "ranking" && <RankingTab />}
        {value === "feargreed" && <FearGreedTab />}
      </Box>
    </Box>
  );
};

export default OtherPage;
