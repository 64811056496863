import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import BaseDialog from "../../../../Common/BaseDialog/BaseDialog";
import { backtestingSelector } from "../../../../selectors";
import BacktestingChart from "../BacktestingChart";
import BacktestingTopBarLeft from "./BacktestingTopBarLeft";
import BacktestingTopBarRight from "./BacktestingTopBarRight";
import BacktestingUploadTopBar from "../Upload/BacktestingUploadTopBar";
import BacktestingUploadFields from "../Upload/BacktestingUploadFields";
import BacktestingUploadInfo from "../Upload/BacktestingUploadInfo";

const BacktestingTopBar: React.FC = () => {
  const { fullScreenOpen, uploadOpen } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const [dialogHeight, setDialogHeight] = useState(0);
  const [topBarHeight, setTopBarHeight] = useState(0);

  const dialogRef = useCallback((node: any) => {
    if (node !== null) {
      setDialogHeight(node.clientHeight);
    }
  }, []);

  const topBarRef = useCallback((node: any) => {
    if (node !== null) {
      setTopBarHeight(node.clientHeight);
    }
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
        flexDirection: "row",
      }}
    >
      <BacktestingTopBarLeft />
      <BacktestingTopBarRight />
      <BaseDialog
        open={fullScreenOpen}
        fullScreen
        padding={2}
        content={
          <Box sx={{ height: "100%" }} ref={dialogRef}>
            <Stack
              ref={topBarRef}
              sx={{
                width: "100%",
                background: "rgba(96, 130, 182, 0.6)",
                flexDirection: "row",
              }}
            >
              <BacktestingTopBarLeft />
              <BacktestingTopBarRight />
            </Stack>
            <Box>
              {dialogHeight - topBarHeight > 0 && (
                <BacktestingChart
                  height={dialogHeight - topBarHeight}
                  autoSize={false}
                  autoHeight={false}
                  autoWidth
                />
              )}
            </Box>
          </Box>
        }
      />
      <BaseDialog
        open={uploadOpen}
        fullScreen
        padding={2}
        content={
          <>
            <BacktestingUploadTopBar />
            <BacktestingUploadFields />
            <BacktestingUploadInfo />
          </>
        }
      />
    </Stack>
  );
};

export default BacktestingTopBar;
