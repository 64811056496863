import { GridCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import i18n from "i18next";

const getCommonColumns = () => {
  return [
    {
      field: `ID`,
      headerName: i18n.t(`ANALYSIS_GRID_ID`),
      width: 100,
    },
    {
      field: `PR_NAME`,
      headerName: i18n.t(`ANALYSIS_GRID_PR_NAME`),
      width: 120,
    },
    {
      field: `TYPE_NAME`,
      headerName: i18n.t(`ANALYSIS_GRID_TYPE_NAME`),
      width: 120,
    },
    {
      field: `OPENED_AT`,
      headerName: i18n.t(`CANDLE_OPENED_AT`),
      width: 160,
    },
    {
      field: `CLOSED_AT`,
      headerName: i18n.t(`CANDLE_CLOSED_AT`),
      width: 160,
    },
    {
      field: `OPEN`,
      headerName: i18n.t(`CANDLE_OPEN`),
      width: 90,
    },
    {
      field: `CLOSE`,
      headerName: i18n.t(`CANDLE_CLOSE`),
      width: 90,
    },
    {
      field: `HIGH`,
      headerName: i18n.t(`CANDLE_HIGH`),
      width: 90,
    },
    {
      field: `LOW`,
      headerName: i18n.t(`CANDLE_LOW`),
      width: 90,
    },
    {
      field: `SYMBOL_PREFIX`,
      headerName: i18n.t(`ANALYSIS_GRID_SYMBOL_PREFIX`),
      width: 40,
    },
  ];
};

export const getVisibility = () => {
  return {
    ID: false,
    PR_NAME: false,
    TYPE_NAME: false,
    OPENED_AT: false,
    CLOSED_AT: false,
    OPEN: false,
    CLOSE: false,
    HIGH: false,
    LOW: false,
    SYMBOL_PREFIX: false,
    RSIDIV_ST_14: false,
    RSIDIV_ST_21: false,
  };
};

const getColumns = (code: string | undefined) => {
  const common = getCommonColumns();
  switch (code) {
    case "volatility":
      return [
        {
          field: `SYMBOL`,
          headerName: i18n.t(`ANALYSIS_GRID_SYMBOL`),
          width: 110,
          valueGetter: (symbol: any, signal: any) =>
            symbol.replaceAll(signal.SYMBOL_PREFIX, ""),
        },
        {
          field: `BB_TIGHT`,
          headerName: i18n.t(`ANALYSIS_GRID_BB_TIGHT`),
          width: 115,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
        },
        {
          field: `BB_LOOSE`,
          headerName: i18n.t(`ANALYSIS_GRID_BB_LOOSE`),
          width: 120,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
        },
        {
          field: `BB_WIDTH`,
          headerName: i18n.t(`ANALYSIS_GRID_BB_WIDTH`),
          width: 145,
        },
        {
          field: `SMA50_D`,
          headerName: i18n.t(`ANALYSIS_GRID_SMA50_D`),
          width: 150,
          valueGetter: (sma: any) => (sma ? Number(sma.toFixed(2)) : sma),
        },
        {
          field: `SMA200_D`,
          headerName: i18n.t(`ANALYSIS_GRID_SMA200_D`),
          width: 155,
          valueGetter: (sma: any) => (sma ? Number(sma.toFixed(2)) : null),
        },
        {
          field: `ATH_D`,
          headerName: i18n.t(`ANALYSIS_GRID_ATH_D`),
          width: 120,
          valueGetter: (ath: any) => (ath ? Number(ath.toFixed(2)) : null),
        },
        {
          field: `ATL_D`,
          headerName: i18n.t(`ANALYSIS_GRID_ATL_D`),
          width: 120,
          valueGetter: (atl: any) => (atl ? Number(atl.toFixed(2)) : null),
        },
        {
          field: `FLAG_CL`,
          headerName: i18n.t(`ANALYSIS_GRID_FLAG_CL`),
          width: 150,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
        },
        {
          field: `PRICE_UP`,
          headerName: i18n.t(`ANALYSIS_GRID_PRICE_UP`),
          width: 115,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 5,
                }),
        },
        {
          field: `PRICE_DOWN`,
          headerName: i18n.t(`ANALYSIS_GRID_PRICE_DOWN`),
          width: 135,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 5,
                }),
        },
        {
          field: `GREEN`,
          headerName: i18n.t(`ANALYSIS_GRID_GREEN`),
          width: 105,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 7,
                }),
        },
        {
          field: `RED`,
          headerName: i18n.t(`ANALYSIS_GRID_RED`),
          width: 90,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 7,
                }),
        },
        {
          field: `VIX_UP`,
          headerName: i18n.t(`ANALYSIS_GRID_VIX_UP`),
          width: 105,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 7,
                }),
        },
        {
          field: `VIX_DOWN`,
          headerName: i18n.t(`ANALYSIS_GRID_VIX_DOWN`),
          width: 125,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 7,
                }),
        },
        {
          field: `VIX_22`,
          headerName: i18n.t(`ANALYSIS_GRID_VIX_22`),
          width: 115,
        },
        ...common,
      ];
    default:
      return [];
  }
};

export const gridKey = "ID";
export default getColumns;
