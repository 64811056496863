import React from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";

type Props = {
  log: any;
};

const LogItemDetails: React.FC<Props> = ({ log }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: "5%" }}>
      <Box sx={{ paddingBottom: "2%" }}>
        <TextField
          InputLabelProps={{ shrink: true }}
          multiline
          value={`${t("ADMIN_LOG_ITEM_DETAILS_OCCURED_AT_LABEL")}: ${
            log?.OCCURED_AT
          }\n${t("ADMIN_LOG_ITEM_DETAILS_TYPE_LABEL")}: ${t(
            `LOG_TYPE_${log?.TYPE_CODE.toUpperCase()}_LABEL`
          )}`}
          fullWidth
          rows={4}
          label={t("ADMIN_LOG_ITEM_DETAILS_INFO_LABEL")}
          onMouseDown={(e: any) => e.preventDefault()}
          onTouchStart={(e: any) => e.preventDefault()}
        />
      </Box>
      <Box sx={{ paddingBottom: "2%" }}>
        <TextField
          InputLabelProps={{ shrink: true }}
          multiline
          value={log?.MESSAGE || ""}
          fullWidth
          rows={4}
          label={t("ADMIN_LOG_ITEM_DETAILS_MESSAGE_LABEL")}
          onMouseDown={(e: any) => e.preventDefault()}
          onTouchStart={(e: any) => e.preventDefault()}
        />
      </Box>
      {log?.STACK && (
        <Box sx={{ paddingBottom: "2%" }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            multiline
            value={log?.STACK || ""}
            fullWidth
            rows={10}
            label={t("ADMIN_LOG_ITEM_DETAILS_STACK_LABEL")}
            onMouseDown={(e: any) => e.preventDefault()}
            onTouchStart={(e: any) => e.preventDefault()}
          />
        </Box>
      )}
      {log?.METADATA && (
        <Box sx={{ paddingBottom: "2%" }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            multiline
            value={log?.METADATA ? JSON.stringify(log.METADATA, null, 2) : ""}
            fullWidth
            rows={26}
            label={t("ADMIN_LOG_ITEM_DETAILS_METADATA_LABEL")}
            onMouseDown={(e: any) => e.preventDefault()}
            onTouchStart={(e: any) => e.preventDefault()}
          />
        </Box>
      )}
    </Box>
  );
};

export default LogItemDetails;
