import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import LeftRightChart from "../../../../Common/LeftRightChart/LeftRightChart";
import { AppDispatch } from "../../../../../store/configureStore";
import { economySelector } from "../../../../selectors";
import { TAppState } from "../../../../../store/reducers";
import { EChartDrawTypes } from "../../../../../constants/enums";
import { fetchEconomyByExternalId } from "../../../../../store/actions/economy";
import {
  createChartEconomy,
  createHorizontalLine,
} from "../../../../../utilities/helpers";
import {
  getHorizontalLineConfig,
  getLegendLabel,
  getLineConfig,
} from "./../ChartLines";
import { useGetEconomyGroupsQuery } from "../../../../../features/api";
import YieldChart from "./YieldChart";

const EconomyOtherTab: React.FC = () => {
  const { t } = useTranslation();
  const { data: groups, isLoading: isGroupLoading } =
    useGetEconomyGroupsQuery();
  const dispatch = useDispatch<AppDispatch>();
  const { rows } = useSelector((state: TAppState) => economySelector(state));
  const series = useMemo(
    () => groups?.inflation?.series || [],
    [groups?.inflation?.series]
  );

  useEffect(() => {
    if (!isGroupLoading && groups) {
      for (const serie of series) {
        const externalId = serie.serieExternalId;
        dispatch(fetchEconomyByExternalId({ externalId }));
      }
    }
  }, [dispatch, groups, series, isGroupLoading]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexFlow: "wrap", gap: "5%" }}>
          {series.map((serie: any, key: any) => {
            const { serieExternalId, referenceValue } = serie;
            const rightData = rows?.[serieExternalId];
            return (
              <Box
                key={key}
                sx={{
                  minWidth: "90%",
                  flex: 1,
                  paddingBottom: "5%",
                }}
              >
                {isGroupLoading || !rightData ? (
                  <></>
                ) : (
                  <Box>
                    <LeftRightChart
                      fullScreen={false}
                      autoSize
                      height={300}
                      rightDrawType={EChartDrawTypes.Area}
                      rightConfig={getLineConfig(serieExternalId)}
                      rightData={createChartEconomy(rightData)}
                      rightLegendLabel={getLegendLabel(serie)}
                      horizontalLineConfig={getHorizontalLineConfig(serie)}
                      horizontalLineData={createHorizontalLine(
                        rightData,
                        referenceValue
                      )}
                    />
                    <Box sx={{ fontSize: "80%", paddingTop: "2%" }}>
                      <Typography>
                        {t(`ECONOMY_INDEX_DESCRIPTION_${serieExternalId}`)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      <YieldChart />
    </>
  );
};

export default EconomyOtherTab;
