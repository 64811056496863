import React from "react";
import { Box } from "@mui/material";
import RankingGrid from "./Grid/RankingGrid";
import RankingFaq from "./Faq/RankingFaq";

const RankingTab: React.FC = () => {
  return (
    <Box className="ranking-page">
      <RankingGrid />
      <RankingFaq />
    </Box>
  );
};

export default RankingTab;
