import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

type Props = {
  log: any;
};

const LogItemMessage: React.FC<Props> = ({ log }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 4 }}>
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        maxWidth={300}
      >
        {log.MESSAGE ? log.MESSAGE : t("ADMIN_LOGS_ITEM_EMPTY_MESSAGE")}
      </Typography>
    </Box>
  );
};

export default LogItemMessage;
