import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import TradingChart from "../../../Common/TradingChart/TradingChart";
import ChartTopBar from "../../../Common/ChartCommon/TopBar/ChartTopBar";
import ChartDialog from "../../../Common/ChartCommon/ChartDialog";
import { createChartCandles } from "../../../../utilities/helpers";
import { TAppState } from "../../../../store/reducers";
import { analysisSelector } from "../../../selectors";
import { getMarkersConfig } from "./AnalysisChartConfig";
import { getAnalysisMarkers } from "../../../Common/helpers";

const AnalysisChart: React.FC = () => {
  const {
    chart: {
      symbol,
      name,
      data,
      filters: { pair, analysis },
      activeMetrics,
    },
  } = useSelector((state: TAppState) => analysisSelector(state));
  const [analysisDialogIsOpen, setAnalysisDialogIsOpen] = useState(false);
  const markerConfig = getMarkersConfig(analysis?.CODE);

  return (
    <Box sx={{ width: "100%" }}>
      <ChartTopBar
        dialogOpen={false}
        onDialogOpen={() => setAnalysisDialogIsOpen(true)}
      />
      <ChartDialog
        dialogOpen={analysisDialogIsOpen}
        onDialogClose={() => setAnalysisDialogIsOpen(false)}
        chartChild={
          <TradingChart
            autoHeight={false}
            height={400}
            fitContent
            symbol={symbol}
            name={name}
            minMove={pair?.BASE_INCREMENT}
            candleData={createChartCandles(data)}
            markers={getAnalysisMarkers(data, markerConfig, activeMetrics)}
          />
        }
      />
      <TradingChart
        autoHeight={false}
        height={400}
        fitContent
        symbol={symbol}
        name={name}
        minMove={pair?.BASE_INCREMENT}
        candleData={createChartCandles(data)}
        markers={getAnalysisMarkers(data, markerConfig, activeMetrics)}
      />
    </Box>
  );
};

export default AnalysisChart;
