export const enSignalsGridCount = {
  SIGNALS_GRID_COUNT_HEADER_SIGNAL: "Signal",
  SIGNALS_GRID_COUNT_HEADER_PAIRS: "Pairs",
  SIGNALS_GRID_COUNT_HEADER_PERCENT: "Percent",
  SIGNALS_GRID_COUNT_HEADER_BAR: "Bar",
};

export const elSignalsGridCount = {
  SIGNALS_GRID_COUNT_HEADER_SIGNAL: "Σήμα",
  SIGNALS_GRID_COUNT_HEADER_PAIRS: "Ζεύγη",
  SIGNALS_GRID_COUNT_HEADER_PERCENT: "Ποσοστό",
  SIGNALS_GRID_COUNT_HEADER_BAR: "Γράφημα",
};
