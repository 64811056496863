import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import DateDisplay from "./../../Common/DateDisplay/DateDisplay";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const getDateFlex = (width: number) => {
    if (width <= 500) return 3;
    else if (width > 500 && width <= 800) return 2;
    else return 1;
  };

  return (
    <Box className="app-footer-container" sx={{ display: "flex" }}>
      <Box sx={{ flex: 1 }}>
        <Typography>{t("APP_FOOTER_COPYRIGHT")}</Typography>
      </Box>
      <Box sx={{ flex: 1 }}></Box>
      <Box
        sx={{
          flex: getDateFlex(window.innerWidth),
          fontSize: "85%",
        }}
      >
        <DateDisplay />
      </Box>
    </Box>
  );
};

export default Footer;
