export const enMenu = {
  MAIN_MENU_ITEM_HOME: "Home",
  MAIN_MENU_ITEM_SIGNALS: "Signals",
  MAIN_MENU_ITEM_ANALYSIS: "Analysis",
  MAIN_MENU_ITEM_CHARTS: "Charts",
  MAIN_MENU_ITEM_ECONOMY: "Economy",
  MAIN_MENU_ITEM_OTHER: "Other",
  USER_MENU_ITEM_LOGOUT: "Logout",
  USER_MENU_ITEM_SETTINGS: "Settings",
  USER_MENU_ITEM_ADMIN: "Admin",
};

export const elMenu = {
  MAIN_MENU_ITEM_HOME: "Αρχική",
  MAIN_MENU_ITEM_SIGNALS: "Σήματα",
  MAIN_MENU_ITEM_ANALYSIS: "Ανάλυση",
  MAIN_MENU_ITEM_CHARTS: "Γραφήματα",
  MAIN_MENU_ITEM_ECONOMY: "Οικονομία",
  MAIN_MENU_ITEM_OTHER: "Άλλα",
  USER_MENU_ITEM_LOGOUT: "Αποσύνδεση",
  USER_MENU_ITEM_SETTINGS: "Ρυθμίσεις",
  USER_MENU_ITEM_ADMIN: "Διαχείριση",
};
