import React from "react";
import GaugeChart from "react-gauge-chart";
import { Box } from "@mui/material";

type Props = {
  levels?: number;
  percent: number;
  arcsLength?: Array<number>;
  arcPadding?: number;
  arcWidth?: number;
  colors?: Array<string> | undefined;
  cornerRadius?: number;
  needleColor?: string;
  needleBaseColor?: string;
  textColor?: string;
  marginInPercent?: number;
  animate?: boolean;
  formatText?: (value: any) => string;
};

const MeterChart: React.FC<Props> = ({
  levels,
  percent,
  arcsLength,
  arcPadding = 0.02,
  arcWidth = 0.12,
  colors = undefined,
  cornerRadius = 0,
  needleColor = "#40E0D0",
  needleBaseColor = "#40E0D0",
  textColor = "#40E0D0",
  marginInPercent = 0.02,
  animate = true,
  formatText = (value) => `${value}%`,
}) => {
  return (
    <Box>
      <GaugeChart
        nrOfLevels={levels}
        percent={percent}
        arcsLength={arcsLength}
        arcPadding={arcPadding}
        arcWidth={arcWidth}
        colors={colors}
        cornerRadius={cornerRadius}
        needleColor={needleColor}
        needleBaseColor={needleBaseColor}
        textColor={textColor}
        marginInPercent={marginInPercent}
        animate={animate}
        formatTextValue={formatText}
      />
    </Box>
  );
};

export default MeterChart;
