import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "../../../../store/reducers";
import { signalSelector } from "../../../selectors";
import { useGetActiveIndicatorsQuery } from "../../../../features/api";
import { createIndicatorsConfig } from "../../helpers";
import { TIndicatorsConfig } from "../../../../types/entities";
import SignalsAnalysisAllTime from "./Content/SignalsAnalysisAllTime";
import SignalsAnalysisHeader from "./Content/SignalsAnalysisHeader";
import SignalsAnalysisCandle from "./Content/SignalsAnalysisCandle";
import SignalsAnalysisIndicators from "./Content/SignalsAnalysisIndicators";

const SignalsAnalysisContent: React.FC = () => {
  const { data: indicators, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const [indicatorsConfig, setIndicatorsConfig] = useState<TIndicatorsConfig>();
  const {
    analysis: {
      filters: { period },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  useEffect(() => {
    if (indicators) {
      setIndicatorsConfig(createIndicatorsConfig(period, indicators));
    }
  }, [period, indicatorsLoading, indicators]);

  return (
    <Box sx={{ padding: 1 }}>
      <SignalsAnalysisHeader />
      <SignalsAnalysisAllTime />
      <SignalsAnalysisCandle />
      <Box
        sx={{
          padding: 2,
          marginBottom: 2,
          border: "3px solid rgba(114, 108, 111, 0.40)",
          borderRadius: "15px",
          MozBorderRadius: "15px",
        }}
      >
        <SignalsAnalysisIndicators config={indicatorsConfig?.price} />
        <SignalsAnalysisIndicators config={indicatorsConfig?.range} />
        <SignalsAnalysisIndicators config={indicatorsConfig?.noRange} />
        <SignalsAnalysisIndicators config={indicatorsConfig?.histogram} />
      </Box>
    </Box>
  );
};

export default SignalsAnalysisContent;
