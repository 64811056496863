import * as React from "react";
import QRCode from "react-qr-code";
import { Box } from "@mui/material";

type Props = {
  value: string;
  size?: number;
  viewBox?: string;
};

const BaseQrCode: React.FC<Props> = ({
  value = "",
  size = 200,
  viewBox = `0 0 0 0`,
}) => {
  return (
    <Box sx={{ background: "white", padding: 1 }}>
      <QRCode
        size={size}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={value}
        viewBox={viewBox}
      />
    </Box>
  );
};

export default BaseQrCode;
