import * as React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { charIsNumeric, charIsText } from "../helpers";

type Props = {
  onChange?: (value: any) => void;
  onKeyUp?: (value: any) => void;
  onComplete: (value: any) => void;
  length?: number;
  autoFocus?: boolean;
  validation?: "text" | "numeric" | "none";
};

const BaseAuthCode: React.FC<Props> = ({
  onChange = (value: any) => {},
  onKeyUp = (value: any) => {},
  onComplete,
  length = 6,
  autoFocus = true,
  validation = "numeric",
}) => {
  const [otp, setOtp] = React.useState("");
  const InputProps =
    validation === "numeric"
      ? {
          type: "number",
          sx: {
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              { display: "none" },
            "& input[type=number]": { MozAppearance: "textfield" },
          },
        }
      : {};

  const handleChange = (value: any) => {
    onChange(value);
    setOtp(value);
  };

  const validateChar = (value: any) => {
    switch (value) {
      case "numeric":
        return charIsNumeric(value);
      case "text":
        return charIsText(value);
      default:
        return true;
    }
  };

  return (
    <MuiOtpInput
      inputMode="numeric"
      value={otp}
      length={length}
      onChange={handleChange}
      onComplete={onComplete}
      onKeyUp={onKeyUp}
      autoFocus={autoFocus}
      validateChar={validateChar}
      TextFieldsProps={{
        InputProps,
        type: validation === "numeric" ? "number" : "text",
      }}
    />
  );
};

export default BaseAuthCode;
