export const enChartsPage = {
  CHARTS_STORED_TAB_LABEL: "Stored",
  CHARTS_LIVE_TAB_LABEL: "Live",
  CHART_SIGNALS_ESCAN_BUY_5: "Enhanced Buy (B)",
  CHART_SIGNALS_ESCAN_SELL_5: "Enhanced Sell (S)",
  CHART_SIGNALS_GOLDEN_CROSS_50_200: "Golden",
  CHART_SIGNALS_DEATH_CROSS_50_200: "Death",
  CHART_SIGNALS_MACD4CDIV_C_BULL_20_50_9: "Classic (B)",
  CHART_SIGNALS_MACD4CDIV_C_BEAR_20_50_9: "Classic (S)",
  CHART_SIGNALS_MACD4CDIV_H_BULL_20_50_9: "Hidden (B)",
  CHART_SIGNALS_MACD4CDIV_H_BEAR_20_50_9: "Hidden (S)",
  CHART_SIGNALS_USCAN_BUY_5: "Ultra Buy (B)",
  CHART_SIGNALS_USCAN_SELL_5: "Ultra Sell (S)",

  CHARTS_LIVE_ADDRESS_LABEL: "Contract address",
  CHARTS_LIVE_JSON_LABEL: "Candlestick data",
  CHARTS_LIVE_JSON_ACCORDION_TITLE:
    "Which is the format of candlestick data field?",
  CHARTS_LIVE_JSON_ACCORDION_DESCRIPTION: `[{"OPENED_AT":"2017-08-17 00:00:00","CLOSED_AT":"2017-08-18 00:00:00","OPEN":4261.48,"CLOSE":4285.08,"HIGH":4485.39,"LOW":4200.74},{"OPENED_AT":"2017-08-18 00:00:00","CLOSED_AT":"2017-08-19 00:00:00","OPEN":4285.08,"CLOSE":4108.37,"HIGH":4371.52,"LOW":3938.77},{"OPENED_AT":"2017-08-19 00:00:00","CLOSED_AT":"2017-08-20 00:00:00","OPEN":4108.37,"CLOSE":4139.98,"HIGH":4184.69,"LOW":3850},{"OPENED_AT":"2017-08-20 00:00:00","CLOSED_AT":"2017-08-21 00:00:00","OPEN":4120.98,"CLOSE":4086.29,"HIGH":4211.08,"LOW":4032.62},{"OPENED_AT":"2017-08-21 00:00:00","CLOSED_AT":"2017-08-22 00:00:00","OPEN":4069.13,"CLOSE":4016,"HIGH":4119.62,"LOW":3911.79},{"OPENED_AT":"2017-08-22 00:00:00","CLOSED_AT":"2017-08-23 00:00:00","OPEN":4016,"CLOSE":4040,"HIGH":4104.82,"LOW":3400},{"OPENED_AT":"2017-08-23 00:00:00","CLOSED_AT":"2017-08-24 00:00:00","OPEN":4040,"CLOSE":4114.01,"HIGH":4265.8,"LOW":4013.89},{"OPENED_AT":"2017-08-24 00:00:00","CLOSED_AT":"2017-08-25 00:00:00","OPEN":4147,"CLOSE":4316.01,"HIGH":4371.68,"LOW":4085.01},{"OPENED_AT":"2017-08-25 00:00:00","CLOSED_AT":"2017-08-26 00:00:00","OPEN":4316.01,"CLOSE":4280.68,"HIGH":4453.91,"LOW":4247.48}]`,
};

export const elChartsPage = {
  CHARTS_STORED_TAB_LABEL: "Αποθηκευμένα",
  CHARTS_LIVE_TAB_LABEL: "Live",
  CHART_SIGNALS_ESCAN_BUY_5: "Enhanced Buy (B)",
  CHART_SIGNALS_ESCAN_SELL_5: "Enhanced Sell (S)",
  CHART_SIGNALS_GOLDEN_CROSS_50_200: "Golden",
  CHART_SIGNALS_DEATH_CROSS_50_200: "Death",
  CHART_SIGNALS_MACD4CDIV_C_BULL_20_50_9: "Classic (B)",
  CHART_SIGNALS_MACD4CDIV_C_BEAR_20_50_9: "Classic (S)",
  CHART_SIGNALS_MACD4CDIV_H_BULL_20_50_9: "Hidden (B)",
  CHART_SIGNALS_MACD4CDIV_H_BEAR_20_50_9: "Hidden (S)",
  CHART_SIGNALS_USCAN_BUY_5: "Ultra Buy (B)",
  CHART_SIGNALS_USCAN_SELL_5: "Ultra Sell (S)",
  CHARTS_LIVE_ADDRESS_LABEL: "Διεύθυνση συμβολαίου",
  CHARTS_LIVE_JSON_LABEL: "Δεδομένα κεριών",
  CHARTS_LIVE_JSON_ACCORDION_TITLE:
    "Ποιά είναι η μορφοποίηση του πεδίου για τα δεδομένα κεριών;",
  CHARTS_LIVE_JSON_ACCORDION_DESCRIPTION: `[{"OPENED_AT":"2017-08-17 00:00:00","CLOSED_AT":"2017-08-18 00:00:00","OPEN":4261.48,"CLOSE":4285.08,"HIGH":4485.39,"LOW":4200.74},{"OPENED_AT":"2017-08-18 00:00:00","CLOSED_AT":"2017-08-19 00:00:00","OPEN":4285.08,"CLOSE":4108.37,"HIGH":4371.52,"LOW":3938.77},{"OPENED_AT":"2017-08-19 00:00:00","CLOSED_AT":"2017-08-20 00:00:00","OPEN":4108.37,"CLOSE":4139.98,"HIGH":4184.69,"LOW":3850},{"OPENED_AT":"2017-08-20 00:00:00","CLOSED_AT":"2017-08-21 00:00:00","OPEN":4120.98,"CLOSE":4086.29,"HIGH":4211.08,"LOW":4032.62},{"OPENED_AT":"2017-08-21 00:00:00","CLOSED_AT":"2017-08-22 00:00:00","OPEN":4069.13,"CLOSE":4016,"HIGH":4119.62,"LOW":3911.79},{"OPENED_AT":"2017-08-22 00:00:00","CLOSED_AT":"2017-08-23 00:00:00","OPEN":4016,"CLOSE":4040,"HIGH":4104.82,"LOW":3400},{"OPENED_AT":"2017-08-23 00:00:00","CLOSED_AT":"2017-08-24 00:00:00","OPEN":4040,"CLOSE":4114.01,"HIGH":4265.8,"LOW":4013.89},{"OPENED_AT":"2017-08-24 00:00:00","CLOSED_AT":"2017-08-25 00:00:00","OPEN":4147,"CLOSE":4316.01,"HIGH":4371.68,"LOW":4085.01},{"OPENED_AT":"2017-08-25 00:00:00","CLOSED_AT":"2017-08-26 00:00:00","OPEN":4316.01,"CLOSE":4280.68,"HIGH":4453.91,"LOW":4247.48}]`,
};
