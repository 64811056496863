import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { fetchFearAndGreed } from "../../../../store/actions/other";
import { AppDispatch } from "../../../../store/configureStore";
import CryptoMeter from "./Meters/CryptoMeter";
import StocksMeter from "./Meters/StocksMeter";
import FearGreedChart from "./Chart/FearGreedChart";

const FearGreedTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchFearAndGreed({ type: "crypto" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFearAndGreed({ type: "stock" }));
  }, [dispatch]);

  return (
    <Box className="feargreed-page">
      <Box sx={{ display: window.innerWidth > 900 ? "flex" : "initial" }}>
        <CryptoMeter />
        <StocksMeter />
      </Box>
      <Box>
        <FearGreedChart />
      </Box>
    </Box>
  );
};

export default FearGreedTab;
