export const enBaseSelect = {
  BASE_SELECT_LOADING: "Loading...",
  BASE_SELECT_SEARCH_PLACEHOLDER: "Type to search...",
  BASE_SELECT_DISPLAY_LIMIT_MESSAGE:
    "*Only the first {{limit}} options are displayed. Please use search to select non displayed options.",
};

export const elBaseSelect = {
  BASE_SELECT_LOADING: "Φόρτωση...",
  BASE_SELECT_SEARCH_PLACEHOLDER: "Πληκτρολογείστε για αναζήτηση...",
  BASE_SELECT_DISPLAY_LIMIT_MESSAGE:
    "*Εμφανίζονται μόνο οι πρώτες {{limit}} εγγραφές. Παρακαλώ χρησιμοποιήστε την αναζήτηση για να επιλέξετε μη εμφανιζόμενες εγγραφές.",
};
