import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import ColorLegend from "../../../Common/ColorLegend/ColorLegend";
import { TAppState } from "../../../../store/reducers";
import { signalSelector } from "../../../selectors";
import { getLegendsConfig } from "./SignalsGridConfig";

const SignalsGridLegend: React.FC = () => {
  const { t } = useTranslation();
  const {
    list: {
      filters: { signal },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  return (
    <Stack
      spacing={{ xs: 1, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginTop={5}
      marginBottom={5}
      justifyContent="center"
    >
      {signal &&
        getLegendsConfig(signal.CODE).map((c, k) => (
          <Box key={k}>
            <ColorLegend
              background={c.background}
              text={t(c.text)}
              shapeHeight="25px"
            />
          </Box>
        ))}
    </Stack>
  );
};

export default SignalsGridLegend;
