import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import FaqAccordion from "../../../Common/FaqAccordion/FaqAccordion";
import { getChartFaqConfig } from "../Chart/AnalysisChartConfig";

const AnalysisChartFaq: React.FC = () => {
  const { t } = useTranslation();
  const faqConfig = getChartFaqConfig();

  return (
    <Box sx={{ padding: "10%", width: "100%" }}>
      {faqConfig?.map((c, k) => (
        <Box key={k} sx={{ paddingBottom: 5 }}>
          <FaqAccordion title={t(c.title)} description={t(c.description)} />
        </Box>
      ))}
    </Box>
  );
};

export default AnalysisChartFaq;
