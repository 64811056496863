import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "../../../../../store/reducers";
import { signalSelector } from "../../../../selectors";

const SignalsAnalysisAllTime: React.FC = () => {
  const { t } = useTranslation();
  const {
    analysis: {
      filters: { pair },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  return (
    <Box
      sx={{
        padding: 2,
        border: "3px solid rgba(114, 108, 111, 0.40)",
        borderRadius: "15px",
        MozBorderRadius: "15px",
        marginBottom: 2,
      }}
    >
      <Typography>
        {t("ALL_TIME_HIGH_LABEL")}: {pair?.ATH}
      </Typography>
      <Typography>
        {t("ALL_TIME_LOW_LABEL")}: {pair?.ATL}
      </Typography>
    </Box>
  );
};

export default SignalsAnalysisAllTime;
