export const enLogActions = {
  ADMIN_LOGS_ACTIONS_CLEAR_TITLE: "Warning",
  ADMIN_LOGS_ACTIONS_CLEAR_MESSAGE:
    "The following action will permanently delete all entries. Would you like to proceed?",
};

export const elLogActions = {
  ADMIN_LOGS_ACTIONS_CLEAR_TITLE: "Προειδοποίηση",
  ADMIN_LOGS_ACTIONS_CLEAR_MESSAGE:
    "Η παρακάτω ενέργεια θα σβήσει οριστικά όλες τις εγγραφές. Επιθυμείτε να προχωρήσετε;",
};
