import React from "react";
import { Box } from "@mui/material";
import TradingChart from "../../../../Common/TradingChart/TradingChart";
import {
  getIndicatorLines,
  getIndicatorMarkers,
  getPatternMarkers,
  getSignalMarkers,
  getVolumeLines,
} from "../../../../Common/helpers";
import { createChartCandles } from "../../../../../utilities/helpers";
import { setLineBarConfig, getVolumeBarConfig } from "../../ChartsLines";
import {
  getSignalConfig,
  getIndicatorPointConfig,
  getPatternConfig,
} from "../../ChartsSignalMarkers";

type Props = {
  pair?: any;
  candleData?: Array<any>;
  indicators: Array<any>;
  patterns: Array<any>;
  patternData?: any;
  signals: Array<any>;
  signalData?: any;
  lineBarConfig: any;
  height?: number;
  autoSize?: boolean;
  autoHeight?: boolean;
  autoWidth?: boolean;
  renderEmpty?: boolean;
  readFromCandles?: boolean;
  publishActions?: boolean;
};

const PriceChart: React.FC<Props> = ({
  lineBarConfig,
  pair = null,
  candleData = [],
  indicators = [],
  patterns = [],
  patternData = {},
  signals = [],
  signalData = {},
  height = 500,
  autoSize = false,
  autoHeight = false,
  autoWidth = true,
  renderEmpty = true,
  readFromCandles = false,
  publishActions = false,
}) => {
  const volumeBarConfig = getVolumeBarConfig();
  const priceLineConfig = setLineBarConfig(lineBarConfig?.price);
  const indicatorPointConfig = getIndicatorPointConfig();
  const patternConfig = getPatternConfig();
  const signalConfig = getSignalConfig();
  return (
    <Box sx={{ width: "100%" }}>
      {candleData.length === 0 && !renderEmpty ? (
        <></>
      ) : (
        <TradingChart
          autoSize={autoSize}
          autoHeight={autoHeight}
          autoWidth={autoWidth}
          height={height}
          fitContent={false}
          symbol={pair?.SYMBOL?.replaceAll(pair?.SYMBOL_PREFIX, "")}
          provider={pair?.PROVIDER_NAME}
          name={pair?.NAME}
          minMove={pair?.BASE_INCREMENT}
          candleData={createChartCandles(candleData)}
          markers={[
            ...getIndicatorMarkers(
              candleData,
              indicatorPointConfig,
              indicators
            ),
            ...getPatternMarkers(
              readFromCandles ? candleData : patternData,
              patternConfig,
              patterns,
              readFromCandles
            ),
            ...getSignalMarkers(
              readFromCandles ? candleData : signalData,
              signalConfig,
              signals,
              readFromCandles
            ),
          ]}
          lineSeries={getIndicatorLines(
            candleData,
            priceLineConfig,
            indicators
          )}
          histogramSeries={getVolumeLines(candleData, volumeBarConfig)}
          enableLeftScale
          eventConfig={
            publishActions && pair
              ? {
                  crosshairMove: {
                    publishEvent: `CrosshairMove/Pair/${pair?.ID}`,
                  },
                  timeRangeChange: {
                    publishEvent: `TimeRangeChange/Pair/${pair?.ID}`,
                  },
                }
              : undefined
          }
        />
      )}
    </Box>
  );
};

export default PriceChart;
