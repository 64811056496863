import { Button } from "@mui/material";

type Props = {
  text?: string | null;
  props?: {
    [key: string]: any;
  };
};

export const BaseTextButton: React.FC<Props> = ({ text, props }) => {
  return <Button {...props}>{text}</Button>;
};
