import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import BaseSelect from "../../Common/BaseSelect/BaseSelect";
import { useState } from "react";
import { commonSelector } from "../../../components/selectors";
import { TAppState } from "../../../store/reducers";
import BaseDialog from "../../Common/BaseDialog/BaseDialog";

type Props = {
  open: boolean;
  onClickOk: (p: any, e: React.SyntheticEvent<unknown>, r?: string) => void;
  onClickCancel: (p: any, e: React.SyntheticEvent<unknown>, r?: string) => void;
};

const SettingsDialog: React.FC<Props> = ({
  open,
  onClickOk,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const { theme, language } = useSelector((state: TAppState) =>
    commonSelector(state)
  );
  const [settingsLocale, setSettingsLocale] = useState(language);
  const [settingsTheme, setSettingsTheme] = useState(theme);

  const onOk = (e: React.SyntheticEvent<unknown>, r?: string) => {
    onClickOk({ locale: settingsLocale, theme: settingsTheme }, e, r);
  };

  const onCancel = (e: React.SyntheticEvent<unknown>, r?: string) => {
    onClickCancel({ locale: settingsLocale, theme: settingsTheme }, e, r);
  };

  return (
    <BaseDialog
      open={open}
      contentPadding={3}
      content={
        <Box>
          <Box sx={{ m: 1, minWidth: 300, display: "flex", padding: 1 }}>
            <Box sx={{ flex: 1 }}>
              <Typography component="label" sx={{ lineHeight: "40px" }}>
                {t("APP_THEME_LABEL")}
              </Typography>
            </Box>
            <Box sx={{ flex: 2 }}>
              <BaseSelect
                inputLabel={t("APP_THEME_LABEL")}
                data={[
                  { title: t("APP_THEME_OPTION_LIGHT"), theme: "light" },
                  { title: t("APP_THEME_OPTION_DARK"), theme: "dark" },
                ]}
                selected={[settingsTheme]}
                onChange={(row: any) => setSettingsTheme(row.theme)}
                displayKey="title"
                selectKey="theme"
              />
            </Box>
          </Box>
          <Box sx={{ m: 1, minWidth: 250, display: "flex", padding: 1 }}>
            <Box sx={{ flex: 1 }}>
              <Typography component="label" sx={{ lineHeight: "40px" }}>
                {t("APP_LANGUAGE_LABEL")}
              </Typography>
            </Box>
            <Box sx={{ flex: 2 }}>
              <BaseSelect
                inputLabel={t("APP_LANGUAGE_LABEL")}
                data={[
                  { title: t("APP_LANGUAGE_OPTION_EL"), locale: "el" },
                  { title: t("APP_LANGUAGE_OPTION_EN"), locale: "en" },
                ]}
                selected={[settingsLocale]}
                onChange={(row: any) => setSettingsLocale(row.locale)}
                displayKey="title"
                selectKey="locale"
              />
            </Box>
          </Box>
        </Box>
      }
      actions={
        <>
          <Button onClick={onCancel}>
            {t("SETTINGS_DIALOG_BUTTON_CANCEL")}
          </Button>
          <Button onClick={onOk}>{t("SETTINGS_DIALOG_BUTTON_OK")}</Button>
        </>
      }
    />
  );
};

export default SettingsDialog;
