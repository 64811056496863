import i18n from "i18next";
import { extendIndicatorsConfig } from "../helpers";

export const getLineBarSettings = (key: string) => {
  let settings: { config: any; style?: any; options?: any } = {
    config: null,
  };
  const line = { lineWidth: 2, lastValueVisible: true, priceLineVisible: true };
  switch (key) {
    case "BOLL_UPPER_20_2":
      settings.config = { color: "#AAFF00", ...line };
      break;
    case "BOLL_MIDDLE_20_2":
      settings.config = { color: "#CD7F32", ...line };
      break;
    case "BOLL_LOWER_20_2":
      settings.config = { color: "#FF2400", ...line };
      break;
    case "KELTNER_UPPER_20":
      settings.config = { color: "#87CEEB", ...line };
      break;
    case "KELTNER_MIDDLE_20":
      settings.config = { color: "#FF6347", ...line };
      break;
    case "KELTNER_LOWER_20":
      settings.config = { color: "#87CEEB", ...line };
      break;
    case "DONCHIAN_UPPER_20":
      settings.config = { color: "#6495ED", ...line };
      break;
    case "DONCHIAN_BASE_20":
      settings.config = { color: "#FFE5B4", ...line };
      break;
    case "DONCHIAN_LOWER_20":
      settings.config = { color: "#6495ED", ...line };
      break;
    case "LOWEST_LOW_5":
      settings.config = { color: "#C23B22", ...line };
      break;
    case "LOWEST_LOW_7":
      settings.config = { color: "#E2062C", ...line };
      break;
    case "HIGHEST_HIGH_5":
      settings.config = { color: "#ACE1AF", ...line };
      break;
    case "HIGHEST_HIGH_7":
      settings.config = { color: "#03C03C", ...line };
      break;
    case "SMA_50":
      settings.config = { color: "#00FFFF", ...line };
      break;
    case "SMA_200":
      settings.config = { color: "#FFBF00", ...line };
      break;
    case "AROON_UPPER_14":
      settings.config = { color: "#4CBB17", ...line };
      break;
    case "AROON_LOWER_14":
      settings.config = { color: "#FF6347", ...line };
      break;
    case "AROON_UPPER_25":
      settings.config = { color: "#00BFFF", ...line };
      break;
    case "AROON_LOWER_25":
      settings.config = { color: "#FF9F00", ...line };
      break;
    case "ADX_14":
      settings.config = { color: "#FF0800", ...line };
      break;
    case "MDI_14":
      settings.config = { color: "#9370DB", ...line };
      break;
    case "PDI_14":
      settings.config = { color: "#A99A86", ...line };
      break;
    case "ADX_20":
      settings.config = { color: "#FF0090", ...line };
      break;
    case "MDI_20":
      settings.config = { color: "#63806b", ...line };
      break;
    case "PDI_20":
      settings.config = { color: "#737000", ...line };
      break;
    case "RSI_7":
      settings.config = { color: "#b069db", ...line };
      break;
    case "RSI_14":
      settings.config = { color: "#A67B5B", ...line };
      break;
    case "RSI_21":
      settings.config = { color: "#2962FF", ...line };
      break;
    case "STOCH_K_5_3_3":
      settings.config = { color: "#FF0800", ...line };
      break;
    case "STOCH_D_5_3_3":
      settings.config = { color: "#0093AF", ...line };
      break;
    case "STOCH_K_14_1_3":
      settings.config = { color: "#FF6FFF", ...line };
      break;
    case "STOCH_D_14_1_3":
      settings.config = { color: "#708090", ...line };
      break;
    case "STOCH_K_21_3_3":
      settings.config = { color: "#0000CD", ...line };
      break;
    case "STOCH_D_21_3_3":
      settings.config = { color: "#66CDAA", ...line };
      break;
    case "VIX_22":
      settings.config = { color: "#ff8c00", ...line };
      break;
    case "MFI_7":
      settings.config = { color: "#FF00FF", ...line };
      break;
    case "MFI_14":
      settings.config = { color: "#7851A9", ...line };
      break;
    case "CCI_14":
      settings.config = { color: "#1E90FF", ...line };
      break;
    case "CCI_20":
      settings.config = { color: "#03C03C", ...line };
      break;
    case "CCI_21":
      settings.config = { color: "#CD853F", ...line };
      break;
    case "HISTOGRAM4C_20_50_9":
      settings.config = { color: "#32CD32" };
      settings.style = {
        field: "HISTOGRAM4C_20_50_9",
        zero: true,
        upColor: "#26a69a",
        downColor: "#ef5350",
        firstCandleUpDown: false,
      };
      break;
  }
  return settings;
};

export const setLineBarConfig = (config: Array<any>) =>
  extendIndicatorsConfig(config, getLineBarSettings);

export const getVolumeBarConfig = () => {
  return [
    {
      code: "volume",
      label: i18n.t("VOLUME_LABEL_1"),
      executions: [
        {
          code: "volume_1",
          label: i18n.t("VOLUME_LABEL_1"),
          output: [
            {
              key: "VOLUME",
              style: {
                field: "CLOSE",
                zero: false,
                upColor: "#26a69a",
                downColor: "#ef5350",
                firstCandleUpDown: true,
              },
              config: {
                color: "#CD7F32",
                priceFormat: {
                  type: "volume",
                },
                priceScaleId: "left",
              },
              options: {
                baseLineVisible: true,
                scaleMargins: {
                  top: 0.8,
                  bottom: 0,
                },
              },
            },
          ],
        },
      ],
    },
  ];
};
