export const enAnalysisGrid = {
  ANALYSIS_GRID_ID: "Id",
  ANALYSIS_GRID_SYMBOL: "Symbol",
  ANALYSIS_GRID_PR_NAME: "Provider",
  ANALYSIS_GRID_BB_TIGHT: "Tight BB",
  ANALYSIS_GRID_BB_LOOSE: "Loose BB",
  ANALYSIS_GRID_BB_WIDTH: "BB width (%)",
  ANALYSIS_GRID_SMA50_D: "From MA50 (%)",
  ANALYSIS_GRID_SMA200_D: "From MA200 (%)",
  ANALYSIS_GRID_ATH_D: "From ATH (%)",
  ANALYSIS_GRID_ATL_D: "From ATL (%)",
  ANALYSIS_GRID_FLAG_CL: "Consolidation",
  ANALYSIS_GRID_PRICE_UP: "Price up",
  ANALYSIS_GRID_PRICE_DOWN: "Price down",
  ANALYSIS_GRID_GREEN: "Green",
  ANALYSIS_GRID_RED: "Red",
  ANALYSIS_GRID_VIX_UP: "Vix up",
  ANALYSIS_GRID_VIX_DOWN: "Vix down",
  ANALYSIS_GRID_VIX_22: "Vix (22)",
  ANALYSIS_GRID_RSIDIV_14: "RSI divergence (14)",
  ANALYSIS_GRID_RSIDIV_21: "RSI divergence (21)",
  ANALYSIS_GRID_MFIDIV_7: "MFI divergence (7)",
  ANALYSIS_GRID_MFIDIV_14: "MFI divergence (14)",
  ANALYSIS_GRID_TAIL: "Tail pattern",
  ANALYSIS_GRID_VALUE_POSITIVE: "Positive",
  ANALYSIS_GRID_VALUE_NEGATIVE: "Negative",
  ANALYSIS_GRID_VALUE_TOPPING: "Topping",
  ANALYSIS_GRID_VALUE_BOTTOMING: "Bottoming",
  ANALYSIS_GRID_SYMBOL_PREFIX: "Symbol prefix",
  ANALYSIS_GRID_TYPE_NAME: "Asset type",
  ANALYSIS_FAQ_TITLE_TIGHT_BB: "What is Tight BB?",
  ANALYSIS_FAQ_DESCRIPTION_TIGHT_BB:
    "The number of consecutive candles that Bollinger Bands are converging.\nIf this value is greater than 5 (depending on the asset type) means that volatility is low and most probably price will move to the side that the first candle will break (lower or upper band).",
  ANALYSIS_FAQ_TITLE_LOOSE_BB: "What is Loose BB?",
  ANALYSIS_DESCRIPTION_FAQ_LOOSE_BB:
    "The number of consecutive candles that Bollinger Bands are diverging.\nIf this value is greater than 5 (depending on the asset type) means that volatility is high and that probably no breakouts will happen.",
  ANALYSIS_FAQ_TITLE_BB_WIDTH: "What is BB Width (%)?",
  ANALYSIS_DESCRIPTION_FAQ_BB_WIDTH:
    "A percentage that is the distance between the upper and lower Bollinger Band.\nIt is related to volatility and higher or lower value means higher or lower volatility respectively.",
  ANALYSIS_FAQ_TITLE_FROM_MA50: "What is From MA50 (%)?",
  ANALYSIS_FAQ_DESCRIPTION_FROM_MA50:
    "A percentage that is the distance of the current price from Moving Average (50). Positive or negative values represent prices higher or lower than the average respectively.\n",
  ANALYSIS_FAQ_TITLE_FROM_MA200: "What is From MA200 (%)?",
  ANALYSIS_FAQ_DESCRIPTION_FROM_MA200:
    "A percentage that is the distance of the current price from Moving Average (200). Positive or Negative values represent prices higher or lower than the average respectively.\n",
  ANALYSIS_FAQ_TITLE_FROM_ATH: "What is From ATH (%)?",
  ANALYSIS_FAQ_DESCRIPTION_FROM_ATH:
    "A percentage that is the distance of the current price from asset's all time high. Positive or negative values represent prices higher or lower than the all time high respectively.\n",
  ANALYSIS_FAQ_TITLE_FROM_ATL: "What is From ATL (%)?",
  ANALYSIS_FAQ_DESCRIPTION_FROM_ATL:
    "A percentage that is the distance of the current price from asset's all time low. Positive or negative values represent prices higher or lower than the all time low respectively.\n",
  ANALYSIS_FAQ_TITLE_CONSOLIDATION: "What is Consolidation?",
  ANALYSIS_FAQ_DESCRIPTION_CONSOLIDATION:
    "The number of consecutive candles that the asset is consolidating.\n",
  ANALYSIS_FAQ_TITLE_PRICE_UP: "What is Price Up?",
  ANALYSIS_FAQ_DESCRIPTION_PRICE_UP:
    "The number of consecutive candles that the asset's price is moving up (strict).\nIf the price moves up for many consecutive candles means that the asset is overbought and a downwards correction is imminent.",
  ANALYSIS_FAQ_TITLE_PRICE_DOWN: "What is Price Down?",
  ANALYSIS_FAQ_DESCRIPTION_PRICE_DOWN:
    "The number of consecutive candles that the asset's price is moving down (strict).\nIf the price moves down for many consecutive candles means that the asset is oversold and an upwards correction is imminent.",
  ANALYSIS_FAQ_TITLE_GREEN: "What is Green?",
  ANALYSIS_FAQ_DESCRIPTION_GREEN:
    "The number of consecutive candles that the asset's price is moving up (less strict).\nIf the price moves up for many consecutive candles means that the asset is overbought and a downwards correction is imminent.",
  ANALYSIS_FAQ_TITLE_RED: "What is Red?",
  ANALYSIS_FAQ_DESCRIPTION_RED:
    "The number of consecutive candles that the asset's price is moving down (less strict).\nIf the price moves down for many consecutive candles means that the asset is oversold and an upwards correction is imminent.",
  ANALYSIS_FAQ_TITLE_VIX_UP: "What is Vix Up?",
  ANALYSIS_FAQ_DESCRIPTION_VIX_UP:
    "The number of consecutive candles that the asset's Vix indicator is moving up. Consecutive Vix up moves imply increasing volatility.\n",
  ANALYSIS_FAQ_TITLE_VIX_DOWN: "What is Vix Down?",
  ANALYSIS_FAQ_DESCRIPTION_VIX_DOWN:
    "The number of consecutive candles that the asset's Vix indicator is moving down. Consecutive Vix down moves imply decreasing volatility.\n",
  ANALYSIS_FAQ_TITLE_VIX_22: "What is Vix (22)?",
  ANALYSIS_FAQ_DESCRIPTION_VIX_22:
    "The value of the asset's Vix indicator (22). The higer or lower the value is, the higher or lower the volatility is respectively.",
};

export const elAnalysisGrid = {
  ANALYSIS_GRID_ID: "Αναγνωριστικό",
  ANALYSIS_GRID_SYMBOL: "Σύμβολο",
  ANALYSIS_GRID_PR_NAME: "Πάροχος",
  ANALYSIS_GRID_BB_TIGHT: "Στενά BB",
  ANALYSIS_GRID_BB_LOOSE: "Χαλαρά BB",
  ANALYSIS_GRID_BB_WIDTH: "Πλάτος BB (%)",
  ANALYSIS_GRID_SMA50_D: "Απόσταση MA50 (%)",
  ANALYSIS_GRID_SMA200_D: "Απόσταση MA200 (%)",
  ANALYSIS_GRID_ATH_D: "Απόσταση ATH (%)",
  ANALYSIS_GRID_ATL_D: "Απόσταση ATL (%)",
  ANALYSIS_GRID_FLAG_CL: "Παγίωση",
  ANALYSIS_GRID_PRICE_UP: "Ανοδική Τιμή",
  ANALYSIS_GRID_PRICE_DOWN: "Καθοδική Τιμή",
  ANALYSIS_GRID_GREEN: "Πράσινη Τιμή",
  ANALYSIS_GRID_RED: "Κόκκινη Τιμή",
  ANALYSIS_GRID_VIX_UP: "Άνοδος Vix",
  ANALYSIS_GRID_VIX_DOWN: "Κάθοδος Vix",
  ANALYSIS_GRID_VIX_22: "Vix (22)",
  ANALYSIS_GRID_RSIDIV_14: "Aπόκλιση RSI (14)",
  ANALYSIS_GRID_RSIDIV_21: "Aπόκλιση RSI (21)",
  ANALYSIS_GRID_MFIDIV_7: "Aπόκλιση MFI (7)",
  ANALYSIS_GRID_MFIDIV_14: "Aπόκλιση MFI (14)",
  ANALYSIS_GRID_TAIL: "Μοτίβο Ουράς",
  ANALYSIS_GRID_VALUE_POSITIVE: "Θετική",
  ANALYSIS_GRID_VALUE_NEGATIVE: "Αρνητική",
  ANALYSIS_GRID_VALUE_TOPPING: "Κορύφωση",
  ANALYSIS_GRID_VALUE_BOTTOMING: "Βύθιση",
  ANALYSIS_GRID_SYMBOL_PREFIX: "Πρόθεμα Συμβόλου",
  ANALYSIS_GRID_TYPE_NAME: "Τύπος στοιχείου",
  ANALYSIS_FAQ_TITLE_TIGHT_BB: "Τι είναι τα Στενά BB;",
  ANALYSIS_FAQ_DESCRIPTION_TIGHT_BB:
    "Ο αριθμός των διαδοχικών κεριών που η άνω και κάτω ζώνες του Bollinger Bands συγκλίνουν.\nΕάν η τιμή αυτή είναι μεγαλύτερη από 5 (ανάλογα με τον τύπο του στοιχείου) σημαίνει ότι η μεταβλητότητα είναι χαμηλή και πιθανόν η τιμή θα κινηθεί προς την πλευρά που η τιμή θα προσπεράση την αντίστοιχη ζώνη (πάνω ή κάτω).",
  ANALYSIS_FAQ_TITLE_LOOSE_BB: "Τι είναι τα Χαλαρά BB;",
  ANALYSIS_DESCRIPTION_FAQ_LOOSE_BB:
    "Ο αριθμός των διαδοχικών κεριών που η άνω και κάτω ζώνες του Bollinger Bands αποκλίνουν.\nΕάν η τιμή αυτή είναι μεγαλύτερη από 5 (ανάλογα με τον τύπο του στοιχείου) σημαίνει ότι η μεταβλητότητα είναι υψηλή και πιθανόν καμία κίνηση τιμής δεν θα πραγματοποιηθεί.",
  ANALYSIS_FAQ_TITLE_BB_WIDTH: "Τι είναι το Πλάτος BB (%);",
  ANALYSIS_DESCRIPTION_FAQ_BB_WIDTH:
    "Ένα ποσοστό που απεικονίζει την απόσταση μεταξύ της άνω και κάτω ζώνης του Bollinger Bands.\nΣχετίζεται με την μεταβλητότητα και υψηλότερη ή χαμηλότερη τιμή απεικονίζει υψηλότερη ή χαμηλότερη μεταβλητότητα αντίστοιχα.",
  ANALYSIS_FAQ_TITLE_FROM_MA50: "Τι είναι η Απόσταση MA50 (%);",
  ANALYSIS_FAQ_DESCRIPTION_FROM_MA50:
    "Ένα ποσοστό που απεικονίζει την απόσταση της τρέχουσας τιμής από τον κινητό μέσο όρο 50 κεριών (Moving Average). Θετικές ή αρνητικές τιμές απεικονίζουν τιμές υψηλότερες ή χαμηλότερες από τον κινητό μέσο όρο αντίστοιχα.\n",
  ANALYSIS_FAQ_TITLE_FROM_MA200: "Τι είναι η Απόσταση MA200 (%);",
  ANALYSIS_FAQ_DESCRIPTION_FROM_MA200:
    "Ένα ποσοστό που απεικονίζει την απόσταση της τρέχουσας τιμής από τον κινητό μέσο όρο 200 κεριών (Moving Average). Θετικές ή αρνητικές τιμές απεικονίζουν τιμές υψηλότερες ή χαμηλότερες από τον κινητό μέσο όρο αντίστοιχα.\n",
  ANALYSIS_FAQ_TITLE_FROM_ATH: "Τι είναι η Απόσταση ATH (%);",
  ANALYSIS_FAQ_DESCRIPTION_FROM_ATH:
    "Ένα ποσοστό που απεικονίζει την απόσταση της τρέχουσας τιμής από την μέγιστη τιμή μέχρι τώρα (All Time High). Θετικές ή αρνητικές τιμές απεικονίζουν τιμές υψηλότερες ή χαμηλότερες από την μέγιστη τιμή αντίστοιχα.\n",
  ANALYSIS_FAQ_TITLE_FROM_ATL: "Τι είναι η Απόσταση ATL (%);",
  ANALYSIS_FAQ_DESCRIPTION_FROM_ATL:
    "Ένα ποσοστό που απεικονίζει την απόσταση της τρέχουσας τιμής από την ελάχιστη τιμή μέχρι τώρα (All Time Low). Θετικές ή αρνητικές τιμές απεικονίζουν τιμές υψηλότερες ή χαμηλότερες από την ελάχιστη τιμή αντίστοιχα.\n",
  ANALYSIS_FAQ_TITLE_CONSOLIDATION: "Τι είναι η Παγίωση;",
  ANALYSIS_FAQ_DESCRIPTION_CONSOLIDATION:
    "Ο αριθμός των διαδοχικών κεριών που το στοιχείο έχει σταθεροποιηθεί και δεν κάνει ακραίες κινήσεις.\n",
  ANALYSIS_FAQ_TITLE_PRICE_UP: "Τι είναι η Ανοδική Τιμή;",
  ANALYSIS_FAQ_DESCRIPTION_PRICE_UP:
    "Ο αριθμός των διαδοχικών κεριών που η τιμή ενός στοιχείου κινείται ανοδικά (αυστηρό).\nΕάν η τιμή κινείται ανοδικά για πολλά διαδοχικά κεριά, σημαίνει ότι το στοιχείο έχει αγοραστεί πολύ και μια καθοδική διόρθωση επίκειται.",
  ANALYSIS_FAQ_TITLE_PRICE_DOWN: "Τι είναι η Καθοδική Τιμή;",
  ANALYSIS_FAQ_DESCRIPTION_PRICE_DOWN:
    "Ο αριθμός των διαδοχικών κεριών που η τιμή ενός στοιχείου κινείται καθοδικά (αυστηρό).\nΕάν η τιμή κινείται καθοδικά για πολλά διαδοχικά κεριά, σημαίνει ότι το στοιχείο έχει πωληθεί πολύ και μια ανοδική διόρθωση επίκειται.",
  ANALYSIS_FAQ_TITLE_GREEN: "Τι είναι η Πράσινη Τιμή;",
  ANALYSIS_FAQ_DESCRIPTION_GREEN:
    "Ο αριθμός των διαδοχικών κεριών που η τιμή ενός στοιχείου κινείται ανοδικά (λιγότερο αυστηρό).\nΕάν η τιμή κινείται ανοδικά για πολλά διαδοχικά κεριά, σημαίνει ότι το στοιχείο έχει αγοραστεί πολύ και μια καθοδική διόρθωση επίκειται.",
  ANALYSIS_FAQ_TITLE_RED: "Τι είναι η Κόκκινη Τιμή;",
  ANALYSIS_FAQ_DESCRIPTION_RED:
    "Ο αριθμός των διαδοχικών κεριών που η τιμή ενός στοιχείου κινείται καθοδικά (λιγότερο αυστηρό).\nΕάν η τιμή κινείται καθοδικά για πολλά διαδοχικά κεριά, σημαίνει ότι το στοιχείο έχει πωληθεί πολύ και μια ανοδική διόρθωση επίκειται.",
  ANALYSIS_FAQ_TITLE_VIX_UP: "Τι είναι η Άνοδος Vix;",
  ANALYSIS_FAQ_DESCRIPTION_VIX_UP:
    "Ο αριθμός των διαδοχικών κεριών που η τιμή του δείκτη Vix κινείται ανοδικά. Διαδοχικές άνοδοι της τιμής του Vix απεικονίζουν αυξανόμενη μεταβλητότητα.\n",
  ANALYSIS_FAQ_TITLE_VIX_DOWN: "Τι είναι η Κάθοδος Vix;",
  ANALYSIS_FAQ_DESCRIPTION_VIX_DOWN:
    "Ο αριθμός των διαδοχικών κεριών που η τιμή του δείκτη Vix κινείται καθοδικά. Διαδοχικές κάθοδοι της τιμής του Vix απεικονίζουν μειούμενη μεταβλητότητα.\n",
  ANALYSIS_FAQ_TITLE_VIX_22: "Τι είναι ο Vix (22);",
  ANALYSIS_FAQ_DESCRIPTION_VIX_22:
    "Η τιμή του δείκτη Vix (22). Όσο υψηλότερη ή χαμηλότερη είναι η τιμή του τόσο υψηλότερη ή χαμηλότερη είναι η μεταβλητότητα αντίστοιχα.",
};
