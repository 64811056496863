import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PATTERN_FETCH } from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "../../features/interceptors/app";
import { patternsUrl } from "./../../constants/endpoints";

type TFetchPatternPayload = {
  periodId: number;
  patternId?: number;
  pairId?: number;
  openedAt?: string;
  closedAt?: string;
};

export const fetchPattern = createAsyncThunk(
  PATTERN_FETCH,
  async (params: TFetchPatternPayload) => {
    const {
      periodId,
      pairId = null,
      patternId = null,
      openedAt = null,
      closedAt = null,
    } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${patternsUrl}?periodId=${periodId}`;
    url += patternId ? `&patternId=${patternId}` : ``;
    url += pairId ? `&pairId=${pairId}` : ``;
    url += openedAt ? `&openedAt=${openedAt}` : ``;
    url += closedAt ? `&closedAt=${closedAt}` : ``;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);
