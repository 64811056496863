import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import FaqAccordion from "../../../../Common/FaqAccordion/FaqAccordion";
import { TAppState } from "../../../../../store/reducers";
import { signalSelector } from "../../../../selectors";
import { getFaqConfig } from "../SignalsGridConfig";

const SignalsGridFaq: React.FC = () => {
  const { t } = useTranslation();
  const {
    list: {
      filters: { signal },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  return (
    <Box sx={{ padding: "10%", width: "100%" }}>
      {signal ? (
        getFaqConfig(signal.CODE).map((c, k) => (
          <Box key={k} sx={{ paddingBottom: "5%" }}>
            <FaqAccordion title={t(c.title)} description={t(c.description)} />
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SignalsGridFaq;
