import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { AppDispatch } from "../../../../../store/configureStore";
import { clearAppLogs, fetchAppLogs } from "../../../../../store/actions/logs";
import {
  ClearAllButton,
  PlayButton,
  StopButton,
} from "../../../../Common/BaseButton/Buttons";
import { getUserInStorage } from "../../../../../utilities/helpers";
import { UserRoles } from "../../../../../constants/enums";
import { POLLING_MILLIS } from "../../../../../constants/settings";
import { listLogsClear } from "../../../../../store/reducers/admin";
import BaseConfirmDialog from "../../../../Common/BaseConfirmDialog/BaseConfirmDialog";

const LogActions: React.FC = () => {
  const profileData = getUserInStorage();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [intervalId, setIntervalId] = useState<any>(null);
  const [clearOpen, setClearOpen] = useState<boolean>(false);

  const onStartLive = () => {
    const interval = setInterval(() => {
      dispatch(listLogsClear());
      dispatch(fetchAppLogs({ limit: 10 }));
    }, POLLING_MILLIS);
    setIntervalId(interval);
  };

  const onStopLive = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const onClear = () => {
    dispatch(clearAppLogs());
    setClearOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "0px 10px 0px 10px",
        marginBottom: "20px",
        height: 40,
      }}
    >
      <Box sx={{ flex: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t("ADMIN_LOGS_HEADER")}
        </Typography>
      </Box>
      <Box sx={{ display: "contents", flex: 1 }}>
        {intervalId === null ? (
          <PlayButton onClick={onStartLive} />
        ) : (
          <StopButton onClick={onStopLive} />
        )}
        {profileData?.role === UserRoles.Admin && (
          <>
            <BaseConfirmDialog
              title={t("ADMIN_LOGS_ACTIONS_CLEAR_TITLE")}
              open={clearOpen}
              message={t("ADMIN_LOGS_ACTIONS_CLEAR_MESSAGE")}
              onCancel={() => setClearOpen(false)}
              onOk={() => onClear()}
            />
            <ClearAllButton onClick={() => setClearOpen(true)} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default LogActions;
