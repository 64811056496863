export const publish = (name: string, data?: any) =>
  window.dispatchEvent(new CustomEvent(name, { detail: data }));

export const subscribe = (name: string, callback: (params: any) => void) =>
  window.addEventListener(name, (event: CustomEventInit<any> | undefined) =>
    callback(event?.detail)
  );

export const unsubscribe = (name: string, callback: (params: any) => void) =>
  window.removeEventListener(name, callback);
