/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { DateTime } from "luxon";
import { Box, Stack } from "@mui/material";
import BaseDatePicker from "../../../Common/BaseDatePicker/BaseDatePicker";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import { TAppState } from "../../../../store/reducers";
import { AppDispatch } from "../../../../store/configureStore";
import {
  listPeriodChanged,
  listOpenedAtChanged,
  listAnalysisChanged,
  listDatasetAnalysesChanged,
  listDatasetPeriodsChanged,
} from "../../../../store/reducers/analysis";
import { analysisSelector } from "../../../selectors";
import {
  useGetActiveAnalysesQuery,
  useGetActivePeriodsQuery,
} from "../../../../features/api";
import { getMaxDate, disableDates } from "../../../../utilities/helpers";
import { fetchLastSynced } from "../../../../store/actions/period";
import { filterTabularAnalyses } from "../../../../utilities/filters";
import { fetchAnalysisByOpenedAt } from "../../../../store/actions/analysis";

const AnalysisGridFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: analysisData, isLoading: analysesLoading } =
    useGetActiveAnalysesQuery();

  const {
    list: {
      dataset: { periods, analyses },
      filters: { period, openedAt, analysis },
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  const periodSelected = (period: any) => {
    dispatch(listPeriodChanged(period));
    const filtered = filterTabularAnalyses(analysisData, period);
    dispatch(listDatasetAnalysesChanged(filtered));
    dispatch(listAnalysisChanged(filtered ? first(filtered) : null));
  };

  const analysisSelected = (analysis: any) => {
    const analysisId = analysis.ID;
    const periodId = period.ID;
    dispatch(listAnalysisChanged(analysis));
    dispatch(
      fetchAnalysisByOpenedAt({
        analysisId,
        periodId,
        openedAt: openedAt.toFormat("yyyy-MM-dd HH:mm:ss"),
      })
    );
  };

  const dateSelected = (openedAt: any) => {
    dispatch(listOpenedAtChanged(openedAt));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(listDatasetPeriodsChanged(periodData));
      dispatch(listPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (period && analysisData && !analysesLoading) {
      const filtered = filterTabularAnalyses(analysisData, period);
      dispatch(listDatasetAnalysesChanged(filtered));
      dispatch(listAnalysisChanged(first(filtered)));
    }
  }, [dispatch, period, analysisData, analysesLoading]);

  useEffect(() => {
    const periodId = period?.ID;
    if (periodId) {
      const change = async () => {
        const {
          payload: { openedAt },
        } = await dispatch(fetchLastSynced({ periodId }));
        dispatch(
          listOpenedAtChanged(
            DateTime.fromFormat(openedAt, "yyyy-MM-dd HH:mm:ss", {
              zone: "utc",
            })
          )
        );
      };
      change();
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (period && analysis && openedAt) {
      const analysisId = analysis?.ID;
      const periodId = period?.ID;
      dispatch(
        fetchAnalysisByOpenedAt({
          analysisId,
          periodId,
          openedAt: openedAt.toFormat("yyyy-MM-dd HH:mm:ss"),
        })
      );
    }
  }, [dispatch, openedAt]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selected={[period?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={analyses}
          loading={analysesLoading}
          onChange={analysisSelected}
          selectedDisplay={(row: any) =>
            t(`ANALYSIS_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)
          }
          optionsDisplay={(row: any) =>
            t(`ANALYSIS_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)
          }
          selected={[analysis?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("ANALYSIS_SELECT_INPUT_LABEL")}
          label={t("ANALYSIS_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
        />
      </Box>
      <Box>
        <BaseDatePicker
          label={t("OPENED_AT_DATE_PICKER_LABEL")}
          defaultValue={openedAt}
          maxDate={getMaxDate(period)}
          onChange={dateSelected}
          timeSteps={{ hours: 24, minutes: 60 }}
          shouldDisableDate={disableDates(period)}
        />
      </Box>
    </Stack>
  );
};

export default AnalysisGridFilters;
