import React from "react";
import { Box } from "@mui/material";
import BacktestingTopBar from "./TopBar/BacktestingTopBar";
import BacktestingChart from "./BacktestingChart";

const BacktestingTab: React.FC = () => {
  return (
    <Box className="backtesting-page">
      <BacktestingTopBar />
      <BacktestingChart autoSize />
    </Box>
  );
};

export default BacktestingTab;
