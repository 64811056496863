import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_VERIFY,
  USER_FETCH_SESSION,
  USER_ENABLE_TFA,
  USER_DISABLE_TFA,
  USER_REQUEST_TFA,
  USER_FETCH_SETTINGS,
  USER_UPDATE_SETTINGS,
} from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "./../../features/interceptors/app";
import { userUrl } from "./../../constants/endpoints";
import { removeUserInStorage } from "../../utilities/helpers";

type TLoginPayload = {
  email: string;
  password: string;
  authCode?: string | null;
};

type TVerifyPayload = {
  password: string;
};

type TEnableTfaPayload = {
  secret: string;
  authCode: string;
};

type TDisableTfaPayload = {
  email: string;
  password: string;
};

type TUpdateSettingsPayload = {
  [key: string]: boolean;
};

export const login = createAsyncThunk(
  USER_LOGIN,
  async (params: TLoginPayload) => {
    const { email, password, authCode = null } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(`${userUrl}/login`, {
      email,
      password,
      authCode,
    });
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const logout = createAsyncThunk(USER_LOGOUT, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  const response = await intercepted.instance.post(`${userUrl}/logout`, {});
  removeUserInStorage();
  clearInterceptors(intercepted);
  return response.data;
});

export const verify = createAsyncThunk(
  USER_VERIFY,
  async (params: TVerifyPayload) => {
    const { password } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(`${userUrl}/verify`, {
      password,
    });
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const requestTwoFactor = createAsyncThunk(USER_REQUEST_TFA, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  const response = await intercepted.instance.get(
    `${userUrl}/request-two-factor`
  );
  clearInterceptors(intercepted);
  return response.data;
});

export const enableTwoFactor = createAsyncThunk(
  USER_ENABLE_TFA,
  async (params: TEnableTfaPayload) => {
    const { secret, authCode } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(
      `${userUrl}/enable-two-factor`,
      { secret, authCode }
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const disableTwoFactor = createAsyncThunk(
  USER_DISABLE_TFA,
  async (params: TDisableTfaPayload) => {
    const { email, password } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(
      `${userUrl}/disable-two-factor`,
      { email, password }
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchSession = createAsyncThunk(USER_FETCH_SESSION, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  const response = await intercepted.instance.get(`${userUrl}/session`);
  clearInterceptors(intercepted);
  return response.data;
});

export const fetchSettings = createAsyncThunk(USER_FETCH_SETTINGS, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  const response = await intercepted.instance.get(`${userUrl}/settings`);
  clearInterceptors(intercepted);
  return response.data;
});

export const updateSettings = createAsyncThunk(
  USER_UPDATE_SETTINGS,
  async (params: TUpdateSettingsPayload) => {
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(
      `${userUrl}/update-settings`,
      params
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
