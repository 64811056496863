import { IconButton } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  props: {
    [key: string]: any;
  };
};

export const BaseIconButton: React.FC<Props> = ({ children, props }) => {
  return <IconButton {...props}>{children}</IconButton>;
};
