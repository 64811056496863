import { enRankingTab, elRankingTab } from "./Ranking/translations";
import { enFearGreedTab, elFearGreedTab } from "./FearGreed/translations";

export const enOtherPage = {
  OTHER_RANKINGS_TAB_LABEL: "Rankings",
  OTHER_FEARGREED_TAB_LABEL: "Fear & Greed",
  OTHER_BACKTESTING_TAB_LABEL: "Backtesting",
  ...enRankingTab,
  ...enFearGreedTab,
};

export const elOtherPage = {
  OTHER_RANKINGS_TAB_LABEL: "Κατάταξη",
  OTHER_FEARGREED_TAB_LABEL: "Fear & Greed",
  OTHER_BACKTESTING_TAB_LABEL: "Δοκιμή",
  ...elRankingTab,
  ...elFearGreedTab,
};
