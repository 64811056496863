export const enAnalysisChart = {
  ANALYSIS_FAQ_TITLE_RSI_DIV: "What is RSI divergence?",
  ANALYSIS_FAQ_DESCRIPTION_RSI_DIV:
    "If the asset is on positive or negative RSI divergence. Positive divergence sometimes implies that smart money is buying (RSI increasing despite price decreasing), while negative implies that smart money is selling (RSI decreasing despite price increasing).",
  ANALYSIS_FAQ_TITLE_MFI_DIV: "What is MFI divergence?",
  ANALYSIS_FAQ_DESCRIPTION_MFI_DIV:
    "If the asset is on positive or negative MFI divergence. Positive divergence sometimes implies money inflows to buy the asset, while negative implies that there are money outflows from the asset.",
  ANALYSIS_FAQ_TITLE_TAIL: "What is Tail?",
  ANALYSIS_FAQ_DESCRIPTION_TAIL:
    "It is a candlestick pattern and can be separated in topping and bottoming. Topping tail pattern sometimes implies that the asset has topped out, while bottoming implies the opposite.",
};

export const elAnalysisChart = {
  ANALYSIS_FAQ_TITLE_RSI_DIV: "Τι είναι η Aπόκλιση RSI;",
  ANALYSIS_FAQ_DESCRIPTION_RSI_DIV:
    "Δείχνει εάν ένα στοιχείο είναι σε θετική ή αρνητική απόκλιση RSI. Η θετική απόκλιση κάποιες φορές υποδεικνύει ότι το έξυπνο κεφάλαιο αγοράζει (η τιμή του RSI αυξάνεται ενώ η τιμή του στοιχείου μειώνεται), ενώ η αρνητική τιμή υποδεικνύει ότι το έξυπνο κεφάλαιο πουλάει (η τιμή του RSI μειώνεται ενώ η τιμή του στοιχείου αυξάνεται)",
  ANALYSIS_FAQ_TITLE_MFI_DIV: "Τι είναι η Aπόκλιση MFI;",
  ANALYSIS_FAQ_DESCRIPTION_MFI_DIV:
    "Δείχνει εάν ένα στοιχείο είναι σε θετική ή αρνητική απόκλιση MSI. Η θετική απόκλιση κάποιες φορές υποδεικνύει εισροές κεφαλαίου για το στοιχείο, ενώ η αρνητική τιμή υποδεικνύει εκροές κεφαλαίου για το στοιχείο.",
  ANALYSIS_FAQ_TITLE_TAIL: "Τι είναι το Μοτίβο Ουράς",
  ANALYSIS_FAQ_DESCRIPTION_TAIL:
    "Είναι ένα μοτίβο κεριών που διακρίνεται σε κορυφής (topping) και πυθμένα (bottoming). Το μοτίβο ουράς κορυφής κάποιες φορές υποδεικνύει ότι το στοιχείο έχει φτάσει σε κορύφωση τιμής, ενώ το μοτίβο πυθμένα υποδεικνύει το αντίθετο",
};
