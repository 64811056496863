import i18n from "i18next";
import { extendIndicatorsConfig } from "../helpers";

export const getIndicatorSettings = (key: string) => {
  let settings = { label: i18n.t(`INDICATOR_OUTPUT_${key}`), color: "" };
  switch (key) {
    case "BOLL_UPPER_20_2":
      settings.color = "#AAFF00";
      break;
    case "BOLL_MIDDLE_20_2":
      settings.color = "#CD7F32";
      break;
    case "BOLL_LOWER_20_2":
      settings.color = "#FF2400";
      break;
    case "KELTNER_UPPER_20":
      settings.color = "#87CEEB";
      break;
    case "KELTNER_MIDDLE_20":
      settings.color = "#FF6347";
      break;
    case "KELTNER_LOWER_20":
      settings.color = "#87CEEB";
      break;
    case "DONCHIAN_UPPER_20":
      settings.color = "#6495ED";
      break;
    case "DONCHIAN_BASE_20":
      settings.color = "#FFE5B4";
      break;
    case "DONCHIAN_LOWER_20":
      settings.color = "#6495ED";
      break;
    case "LOWEST_LOW_5":
      settings.color = "#C23B22";
      break;
    case "LOWEST_LOW_7":
      settings.color = "#E2062C";
      break;
    case "HIGHEST_HIGH_5":
      settings.color = "#ACE1AF";
      break;
    case "HIGHEST_HIGH_7":
      settings.color = "#03C03C";
      break;
    case "SMA_50":
      settings.color = "#00FFFF";
      break;
    case "SMA_200":
      settings.color = "#FFBF00";
      break;
    case "AROON_UPPER_14":
      settings.color = "#4CBB17";
      break;
    case "AROON_LOWER_14":
      settings.color = "#FF6347";
      break;
    case "AROON_UPPER_25":
      settings.color = "#00BFFF";
      break;
    case "AROON_LOWER_25":
      settings.color = "#FF9F00";
      break;
    case "ADX_14":
      settings.color = "#FF0800";
      break;
    case "MDI_14":
      settings.color = "#9370DB";
      break;
    case "PDI_14":
      settings.color = "#A99A86";
      break;
    case "ADX_20":
      settings.color = "#FF0090";
      break;
    case "MDI_20":
      settings.color = "#63806b";
      break;
    case "PDI_20":
      settings.color = "#737000";
      break;
    case "RSI_7":
      settings.color = "#b069db";
      break;
    case "RSI_14":
      settings.color = "#A67B5B";
      break;
    case "RSI_21":
      settings.color = "#2962FF";
      break;
    case "STOCH_K_5_3_3":
      settings.color = "#FF0800";
      break;
    case "STOCH_D_5_3_3":
      settings.color = "#0093AF";
      break;
    case "STOCH_K_14_1_3":
      settings.color = "#FF6FFF";
      break;
    case "STOCH_D_14_1_3":
      settings.color = "#708090";
      break;
    case "STOCH_K_21_3_3":
      settings.color = "#0000CD";
      break;
    case "STOCH_D_21_3_3":
      settings.color = "#66CDAA";
      break;
    case "VIX_22":
      settings.color = "#ff8c00";
      break;
    case "MFI_7":
      settings.color = "#FF00FF";
      break;
    case "MFI_14":
      settings.color = "#7851A9";
      break;
    case "CCI_14":
      settings.color = "#1E90FF";
      break;
    case "CCI_20":
      settings.color = "#03C03C";
      break;
    case "CCI_21":
      settings.color = "#CD853F";
      break;
    case "MACD4C_20_50_9":
      settings.color = "#7851A9";
      break;
    case "SIGNAL4C_20_50_9":
      settings.color = "#ff8c00";
      break;
    case "HISTOGRAM4C_20_50_9":
      settings.color = "#32CD32";
      break;
  }
  return settings;
};

export const setIndicatorConfig = (config: Array<any>) =>
  extendIndicatorsConfig(config, getIndicatorSettings);
