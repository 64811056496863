import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { Box, Stack } from "@mui/material";
import TradingChartTopBar from "./TopBar/ChartTopBar";
import BaseDialog from "../BaseDialog/BaseDialog";

type Props = {
  chartChild: ReactElement<any, string | JSXElementConstructor<any>>;
  padding?: string | number;
  dialogOpen: boolean;
  onDialogClose: () => void;
};

const TradingChartDialog: React.FC<Props> = ({
  chartChild,
  dialogOpen = false,
  onDialogClose = () => null,
  padding = 2,
}) => {
  const [dialogHeight, setDialogHeight] = useState(0);
  const [topBarHeight, setTopBarHeight] = useState(0);

  const dialogRef = useCallback((node: any) => {
    if (node !== null) {
      setDialogHeight(node.clientHeight);
    }
  }, []);

  const topBarRef = useCallback((node: any) => {
    if (node !== null) {
      setTopBarHeight(node.clientHeight);
    }
  }, []);

  return (
    <Box>
      <BaseDialog
        open={dialogOpen}
        padding={padding}
        fullScreen
        content={
          <Box sx={{ height: "100%" }} ref={dialogRef}>
            <Stack
              ref={topBarRef}
              sx={{
                background: "rgba(96, 130, 182, 0.6)",
                flexDirection: "row",
              }}
            >
              <TradingChartTopBar
                dialogOpen={true}
                onDialogClose={onDialogClose}
              />
            </Stack>
            <Box>
              {dialogHeight - topBarHeight > 0 &&
                React.cloneElement(chartChild, {
                  height: dialogHeight - topBarHeight,
                  autoSize: false,
                  autoHeight: false,
                  autoWidth: true,
                })}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default TradingChartDialog;
