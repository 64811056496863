import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_STATUSES } from "../../types/entities";
import { clearAppLogs, fetchAppLogs } from "../actions/logs";

export interface TAdminState {
  list: {
    last: Array<any>;
    all: Array<any>;
  };
  status: string | null;
  error: string | null;
}

const initialState: TAdminState = {
  list: {
    last: [],
    all: [],
  },
  status: null,
  error: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    listLogsSet: (state, action) => {
      state.list.all = action.payload;
    },
    listLogsClear: (state) => {
      state.list.all = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppLogs.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(fetchAppLogs.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        state.list.last = action.payload;
        state.list.all = [...state.list.all, ...state.list.last];
      })
      .addCase(fetchAppLogs.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Verification failed";
      })
      .addCase(clearAppLogs.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(clearAppLogs.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        state.list.last = [];
        state.list.all = [];
      })
      .addCase(clearAppLogs.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Verification failed";
      });
  },
});

export const { listLogsSet, listLogsClear } = adminSlice.actions;
export default adminSlice.reducer;
