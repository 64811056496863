import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

type Props = {
  log: any;
};

const LogItemType: React.FC<Props> = ({ log }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 3 }}>
      <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {t(`LOG_TYPE_${log?.TYPE_CODE.toUpperCase()}_LABEL`)}
      </Typography>
    </Box>
  );
};

export default LogItemType;
