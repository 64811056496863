export const enMarketSettings = {
  SETTINGS_NOTIFICATIONS_MARKET_HEADER: "Markets events notifications",
  SETTINGS_NOTIFICATIONS_EMAIL_MARKET_PAIRS_MESSAGE:
    "Enable if you want to receive email notifications when new transaction pairs are being discovered in exchanges or other providers.",
  SETTINGS_NOTIFICATIONS_EMAIL_MARKET_SIGNALS_MESSAGE:
    "Enable if you want to receive email notifications when signal events occur.",
  SETTINGS_NOTIFICATIONS_EMAIL_HEADER: "Via Email",
  SETTINGS_NOTIFICATIONS_STATUS_ON: "On",
  SETTINGS_NOTIFICATIONS_STATUS_OFF: "Off",
};

export const elMarketSettings = {
  SETTINGS_NOTIFICATIONS_MARKET_HEADER: "Ειδοποιήσεις για συμβάντα στις αγορές",
  SETTINGS_NOTIFICATIONS_EMAIL_MARKET_PAIRS_MESSAGE:
    "Ενεργοποιήστε εάν θέλετε να λαμβάνετε ειδοποιήσεις μέσω email όταν εντοπίζονται νέα ζεύγη συναλλαγών σε ανταλλακτήρια ή άλλους παρόχους.",
  SETTINGS_NOTIFICATIONS_EMAIL_MARKET_SIGNALS_MESSAGE:
    "Ενεργοποιήστε εάν θέλετε να λαμβάνετε ειδοποιήσεις μέσω email για συμβάντα σημάτων.",
  SETTINGS_NOTIFICATIONS_EMAIL_HEADER: "Μέσω ηλεκτρονικού ταχυδρομείου",
  SETTINGS_NOTIFICATIONS_STATUS_ON: "Ενεργό",
  SETTINGS_NOTIFICATIONS_STATUS_OFF: "Ανενεργό",
};
