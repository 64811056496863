export const enLogItem = {
  ADMIN_LOGS_ITEM_EMPTY_MESSAGE: "-",
  ADMIN_LOG_ITEM_ACTION_DELETE_TITLE: "Warning",
  ADMIN_LOG_ITEM_ACTION_DELETE_MESSAGE:
    "The following action will permanently delete this entry. Would you like to proceed?",
  ADMIN_LOG_ITEM_DETAILS_INFO_LABEL: "Info",
  ADMIN_LOG_ITEM_DETAILS_MESSAGE_LABEL: "Message",
  ADMIN_LOG_ITEM_DETAILS_STACK_LABEL: "Stack trace",
  ADMIN_LOG_ITEM_DETAILS_METADATA_LABEL: "Details",
  ADMIN_LOG_ITEM_DETAILS_OCCURED_AT_LABEL: "Date",
  ADMIN_LOG_ITEM_DETAILS_TYPE_LABEL: "Type",
};

export const elLogItem = {
  ADMIN_LOGS_ITEM_EMPTY_MESSAGE: "-",
  ADMIN_LOG_ITEM_ACTION_DELETE_TITLE: "Προειδοποίηση",
  ADMIN_LOG_ITEM_ACTION_DELETE_MESSAGE:
    "Η παρακάτω ενέργεια θα σβήσει οριστικά την εγγραφή. Επιθυμείτε να προχωρήσετε;",
  ADMIN_LOG_ITEM_DETAILS_INFO_LABEL: "Πληροφορίες",
  ADMIN_LOG_ITEM_DETAILS_MESSAGE_LABEL: "Μήνυμα",
  ADMIN_LOG_ITEM_DETAILS_STACK_LABEL: "Ίχνος στοίβας",
  ADMIN_LOG_ITEM_DETAILS_METADATA_LABEL: "Λεπτομέρειες",
  ADMIN_LOG_ITEM_DETAILS_OCCURED_AT_LABEL: "Ημερομηνία",
  ADMIN_LOG_ITEM_DETAILS_TYPE_LABEL: "Τύπος",
};
