import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { AppDispatch } from "./../../../store/configureStore";
import ScrollToTopButton from "../../App/ScrollToTop/ScrollToTop";
import Notifications from "../../App/Notifications/Notifications";
import Spinner from "../Spinner/Spinner";
import { fetchAppSettings } from "./../../../store/actions/common";

type Props = {
  pageChild: React.ReactNode;
};

const Content: React.FC<Props> = ({ pageChild }) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchAppSettings());
  });

  return (
    <Box className="app-content-container">
      {pageChild}
      <Notifications />
      <Spinner />
      <ScrollToTopButton />
    </Box>
  );
};

export default Content;
