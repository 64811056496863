import React, { SyntheticEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import SecurityTab from "./Security/SecurityTab";
import NotificationsTab from "./Notifications/NotificationsTab";
import DisplayTab from "./Display/DisplayTab";
import { AppDispatch } from "../../../store/configureStore";
import { fetchSettings } from "../../../store/actions/user";

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("display");
  const dispatch = useDispatch<AppDispatch>();

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <Box>
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<DisplaySettingsIcon />}
            iconPosition="start"
            value="display"
            label={t("SETTINGS_DISPLAY_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<NotificationsIcon />}
            iconPosition="start"
            value="notifications"
            label={t("SETTINGS_NOTIFICATIONS_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<SecurityIcon />}
            iconPosition="start"
            value="security"
            label={t("SETTINGS_SECURITY_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "display" && <DisplayTab />}
        {value === "notifications" && <NotificationsTab />}
        {value === "security" && <SecurityTab />}
      </Box>
    </Box>
  );
};

export default SettingsPage;
