import i18n from "i18next";
import { flatten } from "lodash";

export const filterTabularAnalyses = (analyses: any, period: any) => {
  return analyses?.filter((analysis: any) => {
    const { config } = analysis;
    const { properties, parameters } = config;
    return period?.CODE && parameters[period.CODE] && properties.isTabular;
  });
};

export const filterGraphicalAnalyses = (analyses: any, period: any) => {
  return analyses?.filter((analysis: any) => {
    const { config } = analysis;
    const { properties, parameters } = config;
    return period?.CODE && parameters[period.CODE] && properties.isGraphical;
  });
};

export const filterPairsByProvider = (pairs: any, provider: any) =>
  pairs?.filter((pair: any) => pair.PROVIDER_ID === provider.ID);

export const filterIndicators = (
  indicators: any,
  period: any,
  transform: boolean = true
) => {
  const filtered = indicators?.filter((indicator: any) => {
    const { config } = indicator;
    const { parameters } = config;
    return period?.CODE && parameters[period.CODE];
  });
  if (!transform) return filtered;
  else {
    return flatten(
      filtered.map((indicator: any) => {
        let KEY = 1;
        return indicator.config?.parameters[period.CODE].map((param: any) => ({
          ID: indicator.ID,
          SELECT: `${indicator.CODE}_${KEY++}`,
          TEXT: i18n.t(
            `INDICATOR_DISPLAY_LABEL_${indicator.CODE.toUpperCase()}`
          ),
          CODE: indicator.CODE,
          DISPLAY: `(${Object.keys(param.calculation)
            .map((v) => param.calculation[v])
            .join(", ")})`,
        }));
      })
    );
  }
};

export const filterPatterns = (
  patterns: any,
  period: any,
  transform: boolean = true
) => {
  const filtered = patterns?.filter((pattern: any) => {
    const { config } = pattern;
    const { parameters } = config;
    return period?.CODE && parameters[period.CODE];
  });
  if (!transform) return filtered;
  else {
    return flatten(
      filtered.map((pattern: any) => {
        let KEY = 1;
        return pattern.config?.parameters[period.CODE].map((param: any) => ({
          ID: pattern.ID,
          SELECT: `${pattern.CODE}_${KEY++}`,
          TEXT: i18n.t(
            `PATTERN_SELECT_DISPLAY_LABEL_${pattern.CODE.toUpperCase()}`
          ),
          CODE: pattern.CODE,
          DISPLAY: `(${Object.keys(param.calculation)
            .map((v) => param.calculation[v])
            .join(", ")})`,
        }));
      })
    );
  }
};

export const filterSignals = (signals: any, period: any) => {
  return signals?.filter((signal: any) => {
    const { config } = signal;
    const { parameters } = config;
    return period?.CODE && parameters[period.CODE];
  });
};
