import { API } from "./settings";

export const periodsUrl = `${API.URL}/periods`;
export const candlesUrl = `${API.URL}/candles`;
export const patternsUrl = `${API.URL}/patterns`;
export const signalsUrl = `${API.URL}/signals`;
export const analysesUrl = `${API.URL}/analyses`;
export const economyUrl = `${API.URL}/economy`;
export const fundamentalsUrl = `${API.URL}/fundamentals`;
export const userUrl = `${API.URL}/user`;
export const appUrl = `${API.URL}/app`;
export const appLogsUrl = `${API.URL}/logs/app`;

const extraTimeout = [candlesUrl];

export const checkForExtendedTimeout = (url: string | undefined) =>
  url !== undefined && extraTimeout.filter((u) => url.startsWith(u)).length > 0;
