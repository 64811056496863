import React, { useImperativeHandle, Ref, useState } from "react";
import { forEach } from "lodash";
import { Box, Typography } from "@mui/material";
import { parseDates } from "../helpers";

const AreaLegend = React.forwardRef(
  (props, ref: Ref<{ setLeftRightLegend: any; setTradingLegend: any }>) => {
    const [legends, setLegends] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({ setLeftRightLegend, setTradingLegend }));

    const setLeftRightLegend: (
      legendLabel: string,
      data: Array<any>,
      time: any
    ) => void = (legendLabel, data, time = undefined) => {
      let legends = new Array<any>();
      const values = parseDates(data, time);
      const { value } = values || {};
      legends.push({
        label: legendLabel,
        values: [value !== null ? value : ""],
      });
      setLegends(legends);
    };

    const setTradingLegend = (series: any, time: any = null) => {
      let legends = Array<any>();
      forEach(series, (serie: any) => {
        const { label, lines } = serie;
        let legend = { label, values: Array<any>() };
        forEach(lines, (line: any) => {
          const { data } = line;
          const values = parseDates(data, time);
          const { value } = values || {};
          legend.values.push(value !== null ? value : "");
        });
        legends.push(legend);
      });
      setLegends(legends);
    };

    return (
      <Box>
        {legends.map((legend, key) => (
          <Box sx={{ height: 20 }} key={key}>
            <Typography component="span">{legend.label}: </Typography>
            <Typography component="span">{legend.values.join(" ")} </Typography>
          </Box>
        ))}
      </Box>
    );
  }
);

export default AreaLegend;
