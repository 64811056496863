import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { Box, Button, Typography } from "@mui/material";
import { ROUTES } from "../../../../../constants/routes";
import { AppDispatch } from "../../../../../store/configureStore";
import {
  disableTwoFactor,
  login,
  requestTwoFactor,
  verify,
} from "../../../../../store/actions/user";
import {
  getSettingsInStorage,
  getUserInStorage,
} from "../../../../../utilities/helpers";
import BaseSwitch from "../../../../Common/BaseSwitch/BaseSwitch";
import BasePassword from "../../../../Common/BaseFields/BasePassword/BasePassword";
import { httpErrorChanged } from "../../../../../store/reducers/common";

const TfaSettings: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const user = getUserInStorage();
  const settings = getSettingsInStorage();
  const { forceTwoFactor = null } = settings;

  const submitChange = async () => {
    try {
      if (password) {
        const { verified = false } = await dispatch(
          verify({ password })
        ).unwrap();
        if (verified) {
          const { email } = user;
          if (mode === "enable") {
            const { secret, uri } = await dispatch(requestTwoFactor()).unwrap();
            navigate(ROUTES.TFA_SETUP, {
              state: { email, password, twoFactor: { secret, uri } },
            });
          } else if (mode === "disable") {
            const { enabled = false } = await dispatch(
              disableTwoFactor({ email, password })
            ).unwrap();
            if (!enabled) await dispatch(login({ email, password })).unwrap();
            setMode("");
            setShowPassword(false);
          }
        } else {
          dispatch(
            httpErrorChanged({
              errorStatus: true,
              errorMessage: i18n.t(
                `SETTINGS_SECURITY_TFA_NOTIFICATION_NOT_VERIFIED`
              ),
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
    setPassword("");
  };

  const cancelChange = () => {
    setMode("");
    setShowPassword(false);
  };

  const toggleTfa = (enable = false) => {
    if (enable) setMode("enable");
    else setMode("disable");
    setShowPassword(true);
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t("SETTINGS_SECURITY_TFA_HEADER")}
      </Typography>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("SETTINGS_SECURITY_TFA_APP_HEADER")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 3, mr: 2 }}>
            <Typography>{t("SETTINGS_SECURITY_TFA_APP_MESSAGE")}</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            <BaseSwitch
              checked={user?.twoFactor}
              disabled={forceTwoFactor}
              onChange={(active: any) => toggleTfa(active)}
              label={
                (user?.twoFactor
                  ? t("SETTINGS_SECURITY_TFA_APP_STATUS_ON")
                  : t("SETTINGS_SECURITY_TFA_APP_STATUS_OFF")) || ""
              }
            />
          </Box>
        </Box>
      </Box>
      {showPassword && (
        <Box>
          <Box>
            <BasePassword
              password={password}
              onChange={(password: string) => setPassword(password)}
              onKeyDown={(key: string) => key === "Enter" && submitChange()}
            />
          </Box>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Button variant="contained" sx={{ mr: 2 }} onClick={cancelChange}>
              {t("SETTINGS_SECURITY_TFA_CANCEL_BUTTON")}
            </Button>
            <Button variant="contained" sx={{ mr: 2 }} onClick={submitChange}>
              {t("SETTINGS_SECURITY_TFA_SUBMIT_BUTTON")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TfaSettings;
