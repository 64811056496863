/* eslint-disable react-hooks/exhaustive-deps */
import React, { Ref, useEffect, useRef, useState } from "react";
import { first } from "lodash";
import {
  CandlestickData,
  CandlestickSeriesOptions,
  CandlestickStyleOptions,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  SeriesOptionsCommon,
  Time,
  WhitespaceData,
  createChart,
} from "lightweight-charts";
import i18n from "i18next";
import { Box } from "@mui/material";
import { sortMarkers, toPrecision } from "./../helpers";
import { TChartCandles } from "../../../types/entities";
import CandleLegend from "../ChartCommon/Legends/CandleLegend";
import LineLegend from "../ChartCommon/Legends/LineLegend";
import HistogramLegend from "../ChartCommon/Legends/HistogramLegend";
import TitleLegend from "../ChartCommon/Legends/TitleLegend";
import {
  getCandleOptions,
  getCrosshair,
  getPriceScale,
  getTimeScale,
  getGridLine,
  getLayout,
  getWatermark,
  getOverlayPriceScale,
  getLocalization,
  getHandleScroll,
  getHandleScale,
  getKineticOptions,
  getTrackingModeOptions,
} from "../ChartCommon/Settings/ChartOptions";
import { publish, subscribe, unsubscribe } from "../../../utilities/tools";

const eventPrefix = "TradingChart";

type EventConfig = {
  crosshairMove?: {
    publishEvent?: string | null;
    subscribeEvent?: string | null;
    action?: (params: any) => void;
    follow?: boolean;
  };
  timeRangeChange?: {
    publishEvent?: string | null;
    subscribeEvent?: string | null;
    action?: (params: any) => void;
    follow?: boolean;
  };
  logicalRangeChange?: {
    publishEvent?: string | null;
    subscribeEvent?: string | null;
    action?: (params: any) => void;
    follow?: boolean;
  };
};

type Props = {
  symbol?: string;
  name?: string;
  provider?: string;
  candleData?: TChartCandles;
  lineSeries?: any;
  histogramSeries?: any;
  markers?: any;
  height?: number | undefined;
  width?: number | undefined;
  fullScreen?: boolean;
  autoSize?: boolean;
  showTitleLegend?: boolean;
  showCandleLegend?: boolean;
  showLineLegend?: boolean;
  showHistogramLegend?: boolean;
  enableLeftScale?: boolean;
  enableRightScale?: boolean;
  minMove?: number | null;
  autoWidth?: boolean;
  autoHeight?: boolean;
  fitContent?: boolean;
  eventConfig?: EventConfig;
};
const TradingChart = React.forwardRef(
  (
    {
      symbol = "",
      provider = "",
      name = "",
      candleData = [],
      lineSeries = {},
      histogramSeries = [],
      markers = [],
      height = 500,
      width = 500,
      showTitleLegend = true,
      showCandleLegend = true,
      showLineLegend = true,
      showHistogramLegend = true,
      enableLeftScale = false,
      enableRightScale = true,
      minMove = null,
      autoSize = true,
      autoWidth = true,
      autoHeight = true,
      fitContent = false,
      eventConfig = {
        crosshairMove: {
          action: (params: any) => null,
          publishEvent: null,
          subscribeEvent: null,
          follow: false,
        },
        timeRangeChange: {
          action: (params: any) => null,
          publishEvent: null,
          subscribeEvent: null,
          follow: false,
        },
        logicalRangeChange: {
          action: (params: any) => null,
          publishEvent: null,
          subscribeEvent: null,
          follow: false,
        },
      },
    }: Props,
    ref: Ref<{}>
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const legendsRef = useRef<any>(null);
    const titleRef = useRef<any>(null);
    const candleRef = useRef<any>(null);
    const lineRef = useRef<any>(null);
    const histogramRef = useRef<any>(null);
    const [legendsHeight, setLegendsHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);

    let storedCandles: ISeriesApi<
        "Candlestick",
        Time,
        CandlestickData<Time> | WhitespaceData<Time>,
        CandlestickSeriesOptions,
        DeepPartial<CandlestickStyleOptions & SeriesOptionsCommon>
      >,
      storedLines: any[],
      storedHistograms: any[];

    useEffect(() => {
      if (eventConfig) {
        const { crosshairMove } = eventConfig;
        if (crosshairMove) {
          const {
            action = null,
            subscribeEvent = null,
            follow = false,
          } = crosshairMove;
          if (subscribeEvent) {
            const moveCrosshair = (param: any) => {
              const chart = chartRef.current;
              const line = lineRef.current;
              const histogram = histogramRef.current;
              if (chart) {
                const { time } = param;
                if (time) {
                  for (const serie of storedLines) {
                    const row = first(
                      serie
                        .data()
                        .filter(
                          (row: any) =>
                            row.time.toSeconds() === time.toSeconds()
                        )
                    );
                    if (row) {
                      const { value, time } = row;
                      chart.setCrosshairPosition(value, time, serie);
                      if (line) line.setTradingLegend(lineSeries, time);
                    }
                  }
                  for (const serie of storedHistograms) {
                    const row = first(
                      serie
                        .data()
                        .filter(
                          (row: any) =>
                            row.time.toSeconds() === time.toSeconds()
                        )
                    );
                    if (row) {
                      const { value, time } = row;
                      chart.setCrosshairPosition(value, time, serie);
                      if (histogram)
                        histogram.setTradingLegend(histogramSeries, time);
                    }
                  }
                } else {
                  if (chart) chart.clearCrosshairPosition();
                  if (line) line.setTradingLegend(lineSeries, null);
                  if (histogram)
                    histogram.setTradingLegend(histogramSeries, null);
                }
              }
            };
            action && subscribe(`${eventPrefix}/${subscribeEvent}`, action);
            follow &&
              subscribe(`${eventPrefix}/${subscribeEvent}`, moveCrosshair);
            return () => {
              action && unsubscribe(`${eventPrefix}/${subscribeEvent}`, action);
              follow &&
                unsubscribe(`${eventPrefix}/${subscribeEvent}`, moveCrosshair);
            };
          }
        }
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const crosshairSubscription = (params: any) => {
          const event = eventConfig?.crosshairMove?.publishEvent;
          event && publish(`${eventPrefix}/${event}`, params);
        };
        chart.subscribeCrosshairMove(crosshairSubscription);
        return () => {
          chart.unsubscribeCrosshairMove(crosshairSubscription);
        };
      }
    });

    useEffect(() => {
      if (eventConfig) {
        const { timeRangeChange } = eventConfig;
        if (timeRangeChange) {
          const {
            action = null,
            subscribeEvent = null,
            follow = false,
          } = timeRangeChange;
          if (subscribeEvent) {
            const changeVisibleTimeRange = ({ from, to }: any) => {
              const chart = chartRef.current;
              if (chart) {
                if (
                  from &&
                  to &&
                  (storedCandles ||
                    storedLines.length > 0 ||
                    storedHistograms.length > 0)
                ) {
                  chart.timeScale().setVisibleRange({ from, to });
                }
              }
            };
            action && subscribe(`${eventPrefix}/${subscribeEvent}`, action);
            follow &&
              subscribe(
                `${eventPrefix}/${subscribeEvent}`,
                changeVisibleTimeRange
              );
            return () => {
              action && unsubscribe(`${eventPrefix}/${subscribeEvent}`, action);
              follow &&
                unsubscribe(
                  `${eventPrefix}/${subscribeEvent}`,
                  changeVisibleTimeRange
                );
            };
          }
        }
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const visibleTimeRangeSubscription = (params: any) => {
          const event = eventConfig?.timeRangeChange?.publishEvent;
          event && publish(`${eventPrefix}/${event}`, params);
        };
        chart
          .timeScale()
          .subscribeVisibleTimeRangeChange(visibleTimeRangeSubscription);
        return () => {
          chart
            .timeScale()
            .subscribeVisibleTimeRangeChange(visibleTimeRangeSubscription);
        };
      }
    });

    useEffect(() => {
      if (eventConfig) {
        const { logicalRangeChange } = eventConfig;
        if (logicalRangeChange) {
          const {
            action = null,
            subscribeEvent = null,
            follow = false,
          } = logicalRangeChange;
          if (subscribeEvent) {
            const changeVisibleLogicalRange = ({ from, to }: any) => {
              const chart = chartRef.current;
              if (chart) {
                if (
                  from &&
                  to &&
                  (storedCandles ||
                    storedLines.length > 0 ||
                    storedHistograms.length > 0)
                ) {
                  chart.timeScale().setVisibleLogicalRange({ from, to });
                }
              }
            };
            action && subscribe(`${eventPrefix}/${subscribeEvent}`, action);
            follow &&
              subscribe(
                `${eventPrefix}/${subscribeEvent}`,
                changeVisibleLogicalRange
              );
            return () => {
              action && unsubscribe(`${eventPrefix}/${subscribeEvent}`, action);
              follow &&
                unsubscribe(
                  `${eventPrefix}/${subscribeEvent}`,
                  changeVisibleLogicalRange
                );
            };
          }
        }
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const visibleLogicalRangeSubscription = (params: any) => {
          const event = eventConfig?.logicalRangeChange?.publishEvent;
          event && publish(`${eventPrefix}/${event}`, params);
        };
        chart
          .timeScale()
          .subscribeVisibleLogicalRangeChange(visibleLogicalRangeSubscription);
        return () => {
          chart
            .timeScale()
            .subscribeVisibleLogicalRangeChange(
              visibleLogicalRangeSubscription
            );
        };
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      const container = containerRef.current;
      if (container) {
        if (chart) chart.remove();
        chartRef.current = createChart(container, {
          width: autoWidth ? container.clientWidth : width,
          height: autoHeight ? window.innerHeight : height,
          autoSize: autoSize,
          watermark: getWatermark(
            i18n.t("COMMON_TRADING_CHART_WATERMARK") || "Dcatrader"
          ),
          layout: getLayout(),
          leftPriceScale: getPriceScale(enableLeftScale),
          rightPriceScale: getPriceScale(enableRightScale),
          overlayPriceScales: getOverlayPriceScale(),
          timeScale: getTimeScale(),
          crosshair: getCrosshair(),
          grid: {
            horzLines: getGridLine("#334158"),
            vertLines: getGridLine("#334158"),
          },
          localization: getLocalization(),
          handleScroll: getHandleScroll(),
          handleScale: getHandleScale(),
          kineticScroll: getKineticOptions(),
          trackingMode: getTrackingModeOptions(),
        });
      }
    }, [candleData]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        if (candleData.length > 0) {
          storedCandles = chart.addCandlestickSeries(getCandleOptions());
          storedCandles.setData(candleData);
          if (minMove !== null)
            storedCandles.applyOptions({
              priceFormat: {
                type: "price",
                precision: toPrecision(minMove),
                minMove,
              },
            });
          storedCandles.setMarkers(sortMarkers(markers));
        }
      }
    }, [candleData, markers, minMove]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) fitContent && chart.timeScale().fitContent();
    }, [candleData, lineSeries, histogramSeries, markers]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const candleSubscription = (params: any) => {
          const candle = candleRef.current;
          if (candle) {
            const { time } = params;
            candle.setLegend(candleData, time);
          }
        };
        chart.subscribeCrosshairMove(candleSubscription);
        return () => {
          chart.unsubscribeCrosshairMove(candleSubscription);
        };
      }
    }, [candleData]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        storedLines = [];
        const lineLegend = (params: any) => {
          const { time } = params;
          const { current } = lineRef;
          current.setTradingLegend(lineSeries, time);
        };
        for (const code in lineSeries) {
          const { lines } = lineSeries[code];
          for (const line in lines) {
            const { config, data } = lines[line];
            const serie = chart.addLineSeries(config);
            serie.setData(data);
            storedLines = [...storedLines, serie];
          }
        }
        chart.subscribeCrosshairMove(lineLegend);
        return () => {
          chart.unsubscribeCrosshairMove(lineLegend);
        };
      }
    }, [lineSeries, candleData]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        storedHistograms = [];
        const histogramLegend = (params: any) => {
          if (histogramRef.current) {
            const { time } = params;
            const { current } = histogramRef;
            current.setTradingLegend(histogramSeries, time);
          }
        };
        for (const code in histogramSeries) {
          const { lines } = histogramSeries[code];
          for (const line in lines) {
            const { config, data, options } = lines[line];
            const serie = chart.addHistogramSeries(config);
            serie.priceScale().applyOptions(options);
            serie.setData(data);
            storedHistograms = [...storedHistograms, serie];
          }
        }
        chart.subscribeCrosshairMove(histogramLegend);
        return () => {
          chart.unsubscribeCrosshairMove(histogramLegend);
        };
      }
    }, [histogramSeries, candleData]);

    useEffect(() => {
      /* Necessary: initial legend values */
      const getTitle = () => {
        let text: Array<string> = [];
        if (symbol) text = [...text, symbol];
        if (provider) text = [...text, provider];
        if (name) text = [...text, name];
        return text.join(" / ");
      };
      const title = titleRef.current;
      const candle = candleRef.current;
      const line = lineRef.current;
      const histogram = histogramRef.current;
      if (title) title.setTitleLegend(getTitle());
      if (candle) candle.setLegend(candleData);
      if (line) line.setTradingLegend(lineSeries);
      if (histogram) histogram.setTradingLegend(histogramSeries);
    }, [candleData, histogramSeries, lineSeries, name, provider, symbol]);

    useEffect(() => {
      const legends = legendsRef.current;
      if (legends) {
        const observer = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setLegendsHeight(entry.contentRect.height);
          }
        });
        observer.observe(legends);
        return () => {
          observer.disconnect();
        };
      }
    }, []);

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        const observer = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setContainerWidth(entry.contentRect.width);
          }
        });
        observer.observe(container);
        return () => {
          observer.disconnect();
        };
      }
    }, []);

    useEffect(() => {
      const chart = chartRef.current;
      const container = containerRef.current;
      //Height and width values ignored because 'autoSize' option is enabled
      if (chart && container && !autoSize) {
        let innerHeight = window.innerHeight - legendsHeight;
        //let chartHeight = height - legendsHeight;
        chart.resize(
          autoWidth ? containerWidth : width,
          autoHeight ? innerHeight : height,
          true
        );
      }
    }, [legendsHeight, containerWidth]);

    return (
      <Box>
        <Box
          ref={legendsRef}
          sx={{
            fontFamily: `"Calibri", "Helvetica"`,
            fontWeight: "bold",
            paddingLeft: "2%",
            fontSize: "85%",
            background: "#253248",
            color: "white",
          }}
        >
          {showTitleLegend && <TitleLegend ref={titleRef} />}
          {showCandleLegend && candleData.length > 0 && (
            <CandleLegend ref={candleRef} />
          )}
          {showLineLegend && <LineLegend ref={lineRef} />}
          {showHistogramLegend && <HistogramLegend ref={histogramRef} />}
        </Box>

        <Box ref={containerRef} />
      </Box>
    );
  }
);

export default TradingChart;
