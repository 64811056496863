export const enTfaSettings = {
  SETTINGS_SECURITY_TFA_HEADER: "Two-step verification",
  SETTINGS_SECURITY_TFA_APP_HEADER: "Authenticator App",
  SETTINGS_SECURITY_TFA_APP_MESSAGE:
    "Use Google Authenticator or any other verification app to protect your account.",
  SETTINGS_SECURITY_TFA_APP_STATUS_ON: "On",
  SETTINGS_SECURITY_TFA_APP_STATUS_OFF: "Off",
  SETTINGS_SECURITY_TFA_CANCEL_BUTTON: "Cancel",
  SETTINGS_SECURITY_TFA_SUBMIT_BUTTON: "Submit",
  SETTINGS_SECURITY_TFA_NOTIFICATION_NOT_VERIFIED:
    "Authentication failed, please try again",
};

export const elTfaSettings = {
  SETTINGS_SECURITY_TFA_HEADER: "Ταυτοποίηση δύο παραγόντων",
  SETTINGS_SECURITY_TFA_APP_HEADER: "Εφαρμογή Επαλήθευσης",
  SETTINGS_SECURITY_TFA_APP_MESSAGE:
    "Χρησιμοποιήστε την εφαρμογή Google Authenticator ή κάποια άλλη εφαρμογή επαλήθευσης για να προστατεύσετε τον λογαριασμό σας.",
  SETTINGS_SECURITY_TFA_APP_STATUS_ON: "Ενεργό",
  SETTINGS_SECURITY_TFA_APP_STATUS_OFF: "Ανενεργό",
  SETTINGS_SECURITY_TFA_CANCEL_BUTTON: "Ακύρωση",
  SETTINGS_SECURITY_TFA_SUBMIT_BUTTON: "Υποβολή",
  SETTINGS_SECURITY_TFA_NOTIFICATION_NOT_VERIFIED:
    "Η ταυτοποίηση απέτυχε, παρακαλώ προσπαθήστε ξανά",
};
